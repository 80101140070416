<template>
  <v-app>
    <Login />
  </v-app>
</template>

<script>
import Login from "../components/Login.vue";
export default {
  components: { Login },
};
</script>

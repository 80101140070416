import api from '@/api/index'

export default {
  state: {
    SELECTED_USER: null,
    SIDE_NAV: [],
    USERS: [],
    USER_DETAILS: { name: null },
    USER_OPERATION: null,
    PROFILE_UPDATE: null,
    PROFILE_PATH: null,
    REGISTER_DIALOG: false,
  },

  getters: {
    SELECTED_USER: (state) => state.SELECTED_USER,
    SIDE_NAV: (state) => state.SIDE_NAV,
    USER_DETAILS: (state) => state.USER_DETAILS,
    USERS: (state) => state.USERS,
    USER_OPERATION: (state) => state.USER_OPERATION,
    PROFILE_UPDATE: (state) => state.PROFILE_UPDATE,
    PROFILE_PATH: (state) => state.PROFILE_PATH,
    REGISTER_DIALOG: (state) => state.REGISTER_DIALOG,
  },

  mutations: {
    SELECTED_USER: (state, data) => { state.SELECTED_USER = data },
    SIDE_NAV: (state, data) => { state.SIDE_NAV = data },
    USER_DETAILS: (state, data) => { state.USER_DETAILS = data },
    USERS: (state, data) => { state.USERS = data },
    USER_OPERATION: (state, data) => { state.USER_OPERATION = data },
    PROFILE_UPDATE: (state, data) => { state.PROFILE_UPDATE = data },
    PROFILE_PATH: (state, data) => { state.PROFILE_PATH = data },
    REGISTER_DIALOG: (state, data) => { state.REGISTER_DIALOG = data },
  },

  actions: {
    StoreUser({commit},payload) {
      return new Promise((resolve, reject) => {
        api.post('api/StoreUser',payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    UpdateUser({commit},payload) {
      return new Promise((resolve, reject) => {
        api.put('api/UpdateUser',payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    UpdateUserByUserID({commit},payload) {
      return new Promise((resolve, reject) => {
        api.post('api/UpdateUserByUserID',payload.params,payload.config).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    UpdatePassword({commit},payload) {
      return new Promise((resolve, reject) => {
        api.put('api/UpdatePassword',payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    MakeActive({commit},payload) {
      return new Promise((resolve, reject) => {
        api.put('api/MakeActive',payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    MakeInactive({commit},payload) {
      return new Promise((resolve, reject) => {
        api.put('api/MakeInactive',payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetUserByUserId({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetUserByUserId',payload).then((response) => {
          commit('SELECTED_USER', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    FetchUserData({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/FetchUserData',payload).then((response) => {
          commit('USERS', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetUserDetails({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetUserDetails').then((response) => {
          commit('USER_DETAILS', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetAccessSideNavs({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetAccessSideNavs').then((response) => {
          commit('SIDE_NAV', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    Logout() {
      return new Promise((resolve, reject) => {
        api.post('api/Logout').then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    LOGIN({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post('api/Login', payload).then((response) => {
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

  }
}
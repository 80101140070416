<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Tags</p>
        <TagsTable />
        <TagsDialog v-if="Get_Tag_Dialog_Status == true" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import TagsTable from "@/views/table/tags_table.vue";
import TagsDialog from "@/views/dialog/tags_dialog.vue";
import { mapGetters } from "vuex";
export default {
  components: { TagsTable, TagsDialog },
  computed: {
    ...mapGetters([
      "Get_Tag_Dialog_State_Mode",
      "Get_Tag_Dialog_Status",
      "USER_DETAILS",
      "Get_dept_options",
    ]),
  },
  mounted() {
    this.$store.dispatch("Colleges").then(() => {
      //console.log(this.Get_Colleges)
    });

    if (this.USER_DETAILS) {
      this.$store
        .dispatch("FetchDepartmentOptions", this.USER_DETAILS.college_id)
        .then(() => {});
    }
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

<template>
  <v-app style="background: #f1f2f7">
    <v-contaier class="container">
      <v-card elevation="0" class="users-graph">
        <p class="font-weight-bold">Users</p>
        <user_table />
      </v-card>
    </v-contaier>
  </v-app>
</template>

<script>
import user_table from "@/views/table/user_table.vue";
export default {
  components: { user_table },
};
</script>

<style scoped>
.container {
  padding: 2.5rem 1.8rem;
}
.users-graph {
  padding: 1rem;
  border-top: 4px solid #ff7900;
}
</style>

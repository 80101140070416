
import axios from "axios";

// const URL = "http://127.0.0.1:8000/";
const URL = "https://backend.scierims.com/"; //do not use in development it is connected to cloud db

const instance = axios.create({
    baseURL:URL,
    withCredentials:false
});

instance.interceptors.request.use(function (request) {
    request.headers.common["Content-Type"] = 'application/json,text/html'
    request.headers.common['Accept'] = 'Application/json'
    let token = sessionStorage.getItem("rms-token");
    const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith('session_rms='))
    ?.split('=')[1];
    request.headers.common['Authorization']='Bearer ' + token;
    request.headers.common['Access-Control-Allow-Origin']='*';
    return request;
  }, function (error) {
    return Promise.reject(error);
  });

  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });  
export default instance;

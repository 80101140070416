<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          dense
          hide-details="auto"
          @keydown.enter="department_searchInput()"
          solo
        >
        </v-text-field>
        <!-- <v-icon @click="department_searchInput()">mdi-magnify</v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="9" class="d-flex align-center justify-end">
        <v-btn color="#002147" @click="openDialog()" dark>
          <v-icon class="mr-1">mdi-plus</v-icon> Create New Department
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="GET_DEPT_VAL.data"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="total"
      class="elevation-1 mt-5"
      @update:options="handlePaginationChange"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.college_name }}</td>
          <td>{{ item.name }}</td>
          <!-- <td>{{ item.fname + ' ' + item.mname + ' ' + item.lname }}</td> -->
          <td v-if="item.deleted_at == null">
            <v-chip color="success">Active</v-chip>
          </td>
          <td v-else><v-chip color="error">Inactive</v-chip></td>
          <td>
            <v-icon color="orange"  v-if="item.deleted_at == null" @click="editDept(item)">mdi-pencil</v-icon>
            <v-icon
              color="error"
              @click="delDept(item.id)"
              v-if="item.deleted_at == null"
              >mdi-delete</v-icon
            >
            <v-icon color="success" @click="restoreDept(item.id)" v-else
              >mdi-restore
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
    <deptDialog v-if="GET_DIALOG_VAL == true" />
  </v-card>
</template>

<script>
// comment for template items yon yung getters na pinag pasukan mo ng data
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import store from "@/store";
import deptDialog from "../dialog/DepartmentDialog.vue";
export default {
  components: { deptDialog },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: [
        { text: "College", value: "college_name" },
        { text: "Department", value: "name" },
        // { text: "Chairperson", value: "fname" },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    openDialog() {
      this.$store.commit("set_dialog_val", true); // mutation name
      this.$store.commit("set_dialog_mode", "ADD"); // mutation name
    },
    editDept(item) {
      this.$store.commit("set_dialog_val", true); // mutation name
      this.$store.commit("set_dialog_mode", "EDIT"); // mutation name
      this.$store.commit("SET_EDT_DEPT_VAL", item);
      // console.log(item)
    },
    delDept(id) {
      // this.$store.commit("set_dialog_mode", "DELETE"); // mutation name
      Swal.fire({
        text: "Are you sure to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("deleteDepartment", { id: id })
            .then((response) => {
              // console.log(response)
              Swal.fire(response.title, response.message, response.icon);
              // this.$store.dispatch("getAllDeptVals")
              this.department_fetchTable();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    },
    restoreDept(id) {
      Swal.fire({
        text: "Are you sure to restore this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("restoreDepartment", { id: id })
            .then((response) => {
              // console.log(response)
              Swal.fire(response.title, response.message, response.icon);
              // this.$store.dispatch("getAllDeptVals")
              this.department_fetchTable();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    },
    department_searchInput() {
      this.department_fetchTable();
    },

    async department_fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      await this.$store.dispatch("getAllDeptVals", payload).then(() => {
        this.total = this.GET_DEPT_VAL.total;
        // this.loading = false
      });
    },

    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.department_fetchTable(); // Corrected method name
    },
  },
  watch: {
    GET_IS_NEW_VAL_DEPT: {
      handler(val) {
        if (val === true) {
          // console.log("pumasok dito")
          this.department_fetchTable();
          this.$store.commit("SET_IS_NEW_VAL_DEPT", false);
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "GET_DIALOG_VAL",
      "GET_DIALOG_MODE",
      "GET_DEPT_VAL",
      "GET_DEL_DEPT_VAL",
      "GET_COLLEGE_VAL",
      "GET_IS_NEW_VAL_DEPT",
    ]),
  },
  // mounted lalagay mo yung data pag di na kailangan ng events like onclick
  mounted() {
    // console.log(this.GET_DEPT_VAL.data)
    //pag dispatch actions
    // this.$store.dispatch("getAllDeptVals").then(() => {
    //   // console.log(this.GET_DEPT_VAL)
    // })

    this.$store.dispatch("getAllCollegeVals").then(() => {
      // const colleges = this.GET_COLLEGE_VAL;
      // for (const college of colleges) {
      //   console.log(college.name);
      // }
    });
  },
};
</script>

<template>
  <v-card outlined class="add-research">
    <p class="font-weight-bold">Create Research</p>
    <v-form ref="researchForm">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="payload.researchTitle" label="Title" outlined hide-details="auto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="payload.abstract" label="Abstract" outlined hide-details="auto"></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <v-menu v-model="datePickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field prepended="true" v-model="payload.publication_year" label="Publication Date" readonly
                v-on="on" outlined hide-details="auto"></v-text-field>
            </template>
            <v-date-picker type="month" v-model="payload.publication_year"
              @input="datePickerMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-menu v-model="presentationDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="payload.presentation_date" label="Presentation Date" readonly v-on="on" outlined
                hide-details="auto"></v-text-field>
            </template>
            <v-date-picker v-model="payload.presentation_date" no-title :type="'date'"
              @input="presentationDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field v-model="payload.presentation_location" label="Presentation Location" outlined
            hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-menu v-model="submissionDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="payload.submission_date" label="Submission Date" readonly v-on="on" outlined
                hide-details="auto"></v-text-field>
            </template>
            <v-date-picker v-model="payload.submission_date" no-title @input="submissionDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="payload.details" label="Intellectual Propery Details" outlined
            hide-details="auto"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-autocomplete v-model="payload.funding" :items="items" label="funding" outlined
            hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>



      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.category_ids" :items="categoryOptions" item-text="name" item-value="id"
            label="Category" multiple @change="handleCategoryChange" outlined hide-details="auto"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.tag_ids" :items="tagOptions" item-text="name" item-value="id" label="Tag"
            multiple outlined hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="payload.authors" label="Authors" outlined hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.dept_id" :items="deptOptions" item-text="name" item-value="id"
            label="Department" outlined hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-file-input v-model="payload.pdf_file" label="PDF File" @change="onFileChange" accept=".pdf" outlined
            hide-details="auto"></v-file-input>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="#820909" @click="cancel" dark class="mr-1">Cancel</v-btn>
          <v-btn color="#002147" @click="addResearch()" dark>Submit</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      items: ['Internal', 'External'],
      submissionDateMenu: false,
      datePickerMenu: false,
      presentationDateMenu: false,
      categoryOptions: [], // Fill with actual category data
      tagOptions: [],
      deptOptions: [],
      payload: {
        pdf_file: null,
        researchTitle: "",
        abstract: "",
        publication_year: null,
        presentation_date: null,
        presentation_location: "",
        submission_date: null,
        details: "",
        funding: "",
        category_ids: [],
        tag_ids: [],
        authors: "",
        dept_id: null,
      },
    };
  },
  methods: {
    validatePayload(payload) {
      const requiredKeys = [
        "researchTitle",
        "abstract",
        "publication_year",
        "presentation_date",
        "presentation_location",
        "submission_date",
        "details",
        "funding",
        "category_ids",
        "tag_ids",
        "authors",
        "dept_id",
        "pdf_file",
      ];

      for (const key of requiredKeys) {
        if (key === "category_ids" || key === "tag_ids") {
          // Check if the key is either 'category_ids' or 'tag_ids'
          if (!Array.isArray(payload[key]) || payload[key].length === 0) {
            return false; // Validation failed
          }
        } else {
          // Check for other keys
          if (payload[key] === null || payload[key] === "") {
            return false; // Validation failed
          }
        }
      }

      return true; // Validation passed
    },
    addResearch() {
      const isValid = this.validatePayload(this.payload);
      if (isValid) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            for (const key in this.payload) {
              if (this.payload[key] !== null) {
                // Check if the key is either 'category_ids' or 'tag_ids'
                if (key === "category_ids" || key === "tag_ids") {
                  if (typeof this.payload[key] === "string") {
                    const idsArray = this.payload[key]
                      .split(",")
                      .map((item) => item.trim());
                    formData.append(key, JSON.stringify(idsArray));
                  } else {
                    formData.append(key, JSON.stringify(this.payload[key]));
                  }
                } else {
                  formData.append(key, this.payload[key]);
                }
              }
            }

            this.$store
              .dispatch("addResearch", formData) // Send formData to the backend
              .then((response) => {
                Swal.fire(response.title, response.message, response.icon);
                if (response.status == "success") {
                  this.payload = {
                    researchTitle: "",
                    abstract: "",
                    publication_year: null,
                    presentation_date: null,
                    presentation_location: "",
                    submission_date: null,
                    details: "",
                    category_ids: [],
                    tag_ids: [],
                    authors: "",
                    dept_id: null,
                    pdf_file: null,
                  };
                  this.$store.dispatch("getAllResearchVals").then(() => { });
                  this.$store.commit("set_dialog_val_list", false); // mutation name
                  this.$store.commit("set_dialog_mode_list", null); // mutation name
                }
              })
              .catch((error) => {
                console.error("Error:", error);

                // Check if the error is a 413 (Content Too Large) error
                if (error.response && error.response.status === 413) {
                  Swal.fire({
                    title: "Error",
                    text: "File size exceeds the maximum limit. Please upload a smaller file.",
                    icon: "error",
                  });
                } else {
                  // Handle other errors here
                  Swal.fire({
                    title: "Error",
                    text: "An unexpected error occurred. Please try again later.",
                    icon: "error",
                  });
                }
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "All Fields Are Required.",
        });
      }
    },
    onFileChange(event) {
      if (event.target && event.target.files.length > 0) {
        // Get the selected file
        this.payload.pdf_file = event.target.files[0];
      } else {
        // Handle the case where no file is selected
        // You can display an error message or take other appropriate action
        console.log("no file");
      }
    },

    cancel() {
      this.$store.commit("set_dialog_val_list", false); // mutation name
      this.$store.commit("set_dialog_mode_list", null); // mutation name
    },
    handleCategoryChange() {
      // alert(this.payload.category_ids)
      this.$store
        .dispatch("showDependentTagsDepartment", this.payload.category_ids)
        .then((response) => {
          // console.log(response)
          this.tagOptions = response;
          // this.payload = null
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  computed: {
    yearFormat() {
      // Set the format to display only the year
      return "yyyy";
    },
    ...mapGetters([
      "GET_DIALOG_VAL_LIST",
      "GET_DIALOG_MODE_LIST",
      "GET_COLLEGE_TABLE_VAL",
      "GET_CATEGORY_LIST_VAL",
      "GET_DEPARTMENT_LIST_VAL",
    ]),
  },
  mounted() {
    this.$store.dispatch("getAllDeptVals").then(() => {
      // console.log(this.GET_COLLEGE_TABLE_VAL)
      this.deptOptions = this.GET_DEPARTMENT_LIST_VAL;
    });
    // console.log(this.GET_CATEGORY_LIST_VAL)
    this.categoryOptions = this.GET_CATEGORY_LIST_VAL;

    // console.log()
  },
};
</script>

<style scoped>
.add-research {
  padding: 1rem;
}
</style>

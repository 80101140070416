<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          dense
          hide-details="auto"
          @keydown.enter="searchInput()"
          solo
        >
        </v-text-field>
        <!-- <v-icon @click="searchInput()">mdi-magnify</v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="9" class="d-flex align-center justify-end">
        <v-btn @click="addColleges()" color="#002147" dark
          ><v-icon class="mr-1">mdi-plus</v-icon> Create New College
        </v-btn>
      </v-col>
    </v-row>

    <!-- <DialogComponent @collegeAdded="handleCollegeAdded"></DialogComponent> -->

    <v-data-table
      :headers="headers"
      :items="Get_Colleges.data"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="total"
      class="elevation-1 mt-5"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="handlePaginationChange"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.full_name }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.abbvreviation }}</td>
          <td>
            <span v-if="item.deleted_at === null"
              ><v-chip color="success">Active</v-chip></span
            >
            <span v-else><v-chip color="error">Inactive</v-chip></span>
          </td>
          <td>
            <v-icon  v-if="item.deleted_at == null" @click="editColleges(item, 'Update')" color="orange"
              >mdi-pencil</v-icon
            >
            <v-icon
              v-if="item.deleted_at !== null"
              @click="restoreColleges(item)"
              color="success"
              >mdi-restore</v-icon
            >
            <v-icon v-else @click="deleteColleges(item)" color="error"
              >mdi-delete</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DialogComponent from "@/views/dialog/Colleges_dialog.vue"; // Import your dialog component
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    DialogComponent,
  },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: [
        { text: "Fullname", value: "full_name" },
        { text: "Colleges", value: "name" },
        { text: "Abbreviation", value: "Abbvreviation" },
        { text: "Status", sortable: false },
        { text: "Actions", align: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["Get_Colleges", "Get_S_College", "IS_NEW_VAL"]),
  },
  watch: {
    IS_NEW_VAL: {
      handler(val) {
        if (val === true) {
          this.fetchTable();
          this.$store.commit("IS_NEW_VAL", false);
        }
      },
    },
  },
  methods: {
    addColleges() {
      this.$store.commit("SET_Colleges_DIALOG_STATE_MODE", "Add");
      this.$store.commit("SET_Colleges_DIALOG_STATUS", true);
    },

    editColleges(item, action) {
      // //ipasok sa .then yung dialog and make sure na mauuna ung dispatch bago dialog
      // this.$store.dispatch('Single_Colleges', item.id).then(() => {
      // console.log(this.Get_S_College)

      this.$store.commit("Set_S_College", item);
      this.$store.commit("SET_Colleges_DIALOG_STATE_MODE", "Update");
      this.$store.commit("SET_Colleges_DIALOG_STATUS", true);
      // })
    },

    deleteColleges(item) {
      Swal.fire({
        text: "Are you sure to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Delete_College", item.id).then((response) => {
            Swal.fire("Success", "Removed Successfully!", "success");
            this.fetchTable();
            this.$store.dispatch("Colleges");
          });
        }
      });
    },

    restoreColleges(item) {
      Swal.fire({
        text: "Are you sure to Restore this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, restore it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Restore_College", item.id).then((response) => {
            Swal.fire("Success", "Restored Successfully!", "success");
            this.fetchTable();
            this.$store.dispatch("Colleges");
          });
        }
      });
    },

    searchInput() {
      this.fetchTable();
    },
    async fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      await this.$store.dispatch("Colleges", payload).then(() => {
        this.total = this.Get_Colleges.total;
        this.loading = false;
      });
    },
    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.fetchTable();
    },
  },
  mounted() {
    // console.log(this.Get_Colleges)
  },
};
</script>

import api from '@/api/index'

export default {
  state: {
    Colleges: [],
    mode: null,
    status: false,
    sCollege: [],
    loading: false,
    IS_NEW_VAL: false,
  },

  getters: {
    Get_Colleges: (state) => state.Colleges,
    Get_Colleges_Dialog_Status: (state) => state.status,
    Get_Colleges_Dialog_State_Mode: (state) => state.mode,
    Get_S_College: (state) => state.sCollege,
    is_College_Loading: (state) => state.loading,
    IS_NEW_VAL: (state) => state.IS_NEW_VAL,
  },

  mutations: {
    Set_Colleges: (state, data) => {
      state.Colleges = data;
    },
    SET_Colleges_DIALOG_STATUS: (state, data) => {
      state.status = data;
    },
    SET_Colleges_DIALOG_STATE_MODE: (state, data) => {
      state.mode = data;
    },
    Set_S_College: (state, data) => {
      state.sCollege = data;
    },
    Set_Colleges_Loading(state, loading) {
      state.loading = loading;
    },
    IS_NEW_VAL(state, data) {
      state.IS_NEW_VAL  = data;
    },
  },

  actions: {
 

    Colleges({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/fetchColleges',payload).then((response) => {
          commit('Set_Colleges', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    Single_Colleges({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get(`api/editColleges/${payload}`).then((response) => {
          //console.log(response)
          // commit('Set_S_College', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    
    Store_College({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/addColleges`, payload).then((response) => {
          // commit('Set_Colleges', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },


    Update_College({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id, data } = payload;
        api.put(`api/updateColleges/${id}`, data).then((response) => {
          // commit('Set_S_College', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Delete_College({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.delete(`api/deleteColleges/${payload}`).then((response) => {
          // commit('Set_Colleges', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Restore_College({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/restoreColleges/${payload}`).then((response) => {
          // commit('Set_Colleges', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

  },
};

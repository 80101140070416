import Vue from 'vue';
import VueRouter from 'vue-router';
import user from '@/api/modules/users/index'
import Login from '@/views/SideNav/Login.vue';
import DASHBOARD from '@/views/SideNav/DASHBOARD.vue';
import USERS from '@/views/SideNav/USERS.vue';
import DEPARTMENTS from '@/views/SideNav/DEPARTMENTS.vue';
import ACTIVITY_LOGS from '@/views/SideNav/ACTIVITY_LOGS.vue';
import LIST from '@/views/SideNav/Child/LIST.vue';
import TAGS from '@/views/SideNav/Child/TAGS.vue';
import CATEGORIES from '@/views/SideNav/Child/CATEGORIES.vue';
import ADMIN from '@/views/SideNav/Child/ADMIN.vue';
import DEAN from '@/views/SideNav/Child/DEAN.vue';
import CHAIRPERSON from '@/views/SideNav/Child/CHAIRPERSON.vue';
import PROFESSOR from '@/views/SideNav/Child/PROFESSOR.vue';
import STUDENT from '@/views/SideNav/Child/STUDENT.vue';
import COLLEGES from '@/views/SideNav/COLLEGES.vue';
import USER_PROFILE from '@/views/SideNav/USER_PROFILE.vue';
import CHANGE_PASSWORD from '@/views/components/CHANGE_PASSWORD.vue';
import TEST from '@/views/SideNav/TEST.vue';
import PROF_LIST from '@/views/SideNav/Child/PROF_LIST.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' } // Redirect to the named route
  },
  {
    path: '/RESEARCHES',
    name: 'RESEARCHES',
    redirect: { name: 'LIST' } // Redirect to the named route
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      showSideMenuBar: false, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
      Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        next({ name: response.data[0].name });
      }).catch((error) => {
        next();
      });
    },
  },
  {
    path: '/DASHBOARD',
    name: 'DASHBOARD',
    component: DASHBOARD,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/USERS',
    name: 'USERS',
    component: USERS,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },

  {
    path: '/COLLEGES',
    name: 'COLLEGES',
    component: COLLEGES,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/DEPARTMENTS',
    name: 'DEPARTMENTS',
    component: DEPARTMENTS,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/ACTIVITY%20LOGS',
    name: 'ACTIVITY LOGS',
    component: ACTIVITY_LOGS,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/LIST',
    name: 'LIST',
    component: LIST,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/PROF%20LIST',
    name: 'PROF LIST',
    component: PROF_LIST,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/TAGS',
    name: 'TAGS',
    component: TAGS,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/CATEGORIES',
    name: 'CATEGORIES',
    component: CATEGORIES,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/ADMIN',
    name: 'ADMIN',
    component: ADMIN,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/DEAN',
    name: 'DEAN',
    component: DEAN,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/CHAIRPERSON',
    name: 'CHAIRPERSON',
    component: CHAIRPERSON,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/PROFESSOR',
    name: 'PROFESSOR',
    component: PROFESSOR,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/STUDENT',
    name: 'STUDENT',
    component: STUDENT,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        const hasPermission = response.data.some(permission => permission.name === to.name);
        if (hasPermission) {
          next();
        } else {
          next({ name: response.data[0].name });
        }
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/USER_PROFILE',
    name: 'USER_PROFILE',
    component: USER_PROFILE,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        next();
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/CHANGE_PASSWORD',
    name: 'CHANGE_PASSWORD',
    component: CHANGE_PASSWORD,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        next();
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
  {
    path: '/TEST',
    name: 'TEST',
    component: TEST,
    meta: {
      showSideMenuBar: true, // Set to false to hide the SideMenuBar for the login page
    },
    beforeEnter: (to, from, next) => {
      user.authenticated().then((response) => {
        const shouldShowSideMenuBar = to.meta.showSideMenuBar !== false;
        Vue.prototype.$showSideMenuBar = shouldShowSideMenuBar;
        next();
      }).catch((error) => {
        next({ name: 'Login' });
      });
    },
  },
];

const router = new VueRouter({
  base: '/dist/',
  routes,
});

export default router;

<template>
  <v-card outlined class="view-research">
    <p class="font-weight-bold">View Research</p>
    <v-container>
      <h4 for="Title">Title</h4>
      <p>{{ GET_VIEW_LIST_VAL.research_title }}</p>

      <h4 for="Author ">Author</h4>
      <p>{{ GET_VIEW_LIST_VAL.full_name }}</p>

      <h4 for="Publication Date">Publication Date</h4>
      <p>{{ GET_VIEW_LIST_VAL.research_publication_year }}</p>

      <h4 for="Abstract">Abstract</h4>
      <p>{{ GET_VIEW_LIST_VAL.research_abstract }}</p>

      <h4 for="Intellectual Property Detail"
        >Intellectual Property Detail</h4
      >
      <p>{{ GET_VIEW_LIST_VAL.research_details }}</p>

      <h4 for="Funding"
        >Funding</h4
      >
      <p>{{ GET_VIEW_LIST_VAL.funding }}</p>

      <v-btn color="#820909" @click="cancel" dark>Cancel</v-btn>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$store.commit("set_dialog_val_list", false); // mutation name
      this.$store.commit("set_dialog_mode_list", null); // mutation name
    },
  },
  computed: {
    ...mapGetters(["GET_VIEW_LIST_VAL"]),
  },
  mounted() {
  //  console.log(this.GET_VIEW_LIST_VAL)
  },
};
</script>

<style scoped>
.view-research {
  padding: 1rem;
}
</style>

<template>
  <v-app>
    <v-main>
      <template v-if="$route.meta.showSideMenuBar">
        <v-navigation-drawer app dark v-model="drawer">
          <SideMenuBar />
        </v-navigation-drawer>

        <v-app-bar app elevation="0" style="background: #ff7900" dark>
          <v-icon @click="toggleSidebar">mdi-menu</v-icon>
          <p class="ma-0 ml-2">{{ $route.name }}</p>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text dark v-on="on" class="ml-auto">
                <div>
                  <img
                    :src="PROFILE_PATH"
                    alt="Profile Picture"
                    width="50"
                    class="mr-2 profile-picture"
                  />
                </div>
                {{ USER_DETAILS.full_name }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text block color="primary" @click="profile()"
                  >Profile</v-btn
                >
              </v-list-item>
              <v-list-item>
                <v-btn text block color="secondary" @click="changePassword()"
                  >ChangePassword</v-btn
                >
              </v-list-item>
              <v-list-item>
                <v-btn text block color="error" @click="submitLogout()"
                  >Logout</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
      </template>
      <router-view v-if="routerViewVisible" />
    </v-main>
  </v-app>
</template>

<script>
import SideMenuBar from "@/views/components/SideMenuBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { SideMenuBar },
  data: () => ({
    imageUrl: require("./assets/sample.jpg"),
    routerViewVisible: false,
    drawer: true,
    navbarColor: "white",
  }),
  async created() {
    // Simulate a delay of 1 second (adjust as needed)
    await new Promise((resolve) => setTimeout(resolve, 1500));

    // After the delay, set routerViewVisible to true to render the router-view
    this.routerViewVisible = true;
  },
  computed: {
    ...mapGetters(["SIDE_NAV", "USER_DETAILS", "PROFILE_PATH"]),
  },
  methods: {
    toggleSidebar() {
      this.drawer = !this.drawer;
    },
    profile() {
      this.$router.push("/USER_PROFILE");
    },
    changePassword() {
      this.$router.push("/CHANGE_PASSWORD");
    },
    submitLogout() {
      this.$store.dispatch("Logout").then((response) => {
        if (response["message"] === "success") {
          localStorage.removeItem("rms-token");
          this.$store.commit("SIDE_NAV", null);
          this.$store.commit("USER_DETAILS", {});
          this.$router.push("/Login");
        }
      });
    },
  },
};
</script>

<style>
.profile-picture {
  border-radius: 2rem;
}
</style>

<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          dense
          hide-details="auto"
          @keydown.enter="searchInput()"
          solo
        >
        </v-text-field>
        <!-- <v-icon @click="searchInput()">mdi-magnify</v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="9" class="d-flex align-center justify-end">
        <v-btn
          color="#002147"
          v-if="sidenavViewer !== 'STUDENT'"
          @click="triggerOperation()"
          dark
          ><v-icon class="mr-1">mdi-plus</v-icon> Create New User</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers[sidenavViewer]"
      :items="USERS.data"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="total"
      class="elevation-1 mt-5"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="handlePaginationChange"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.first_name }}</td>
          <td>{{ item.last_name }}</td>
          <td>{{ item.middle_name }}</td>
          <td>{{ item.email }}</td>
          <td v-if="sidenavViewer != 'ADMIN'">{{ item.college }}</td>
          <td
            v-if="
              sidenavViewer === 'PROFESSOR' ||
              sidenavViewer === 'CHAIRPERSON' ||
              sidenavViewer === 'STUDENT'
            "
          >
            {{ item.department }}
          </td>
          <td v-if="sidenavViewer === 'STUDENT'">
            {{ item.year }}
          </td>
          <td v-if="sidenavViewer === 'STUDENT'">
            {{ item.section }}
          </td>
          <td>
            <v-chip v-for="role in item.roles" :key="role.id">
              {{ role.name }}
            </v-chip>
          </td>
          <td>
            <v-chip :color="item.status === 'Active' ? 'success' : 'error'">{{
              item.status
            }}</v-chip>
          </td>
          <td>{{ formattedDate(item.created_at) }}</td>
          <td>{{ formattedDate(item.updated_at) }}</td>
          <td>
            <v-icon @click="viewItem(item)">mdi-eye</v-icon>
            <v-icon @click="editItem(item)" color="orange">mdi-pencil</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    sidenavViewer: String,
  },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: {
        ADMIN: [
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Middle Name", value: "middle_name" },
          { text: "Email", value: "email" },
          { text: "User Type", sortable: false },
          { text: "Status" },
          { text: "Created Date", value: "created_at" },
          { text: "Last Modified Date" },
          { text: "Actions", sortable: false },
        ],
        DEAN: [
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Middle Name", value: "middle_name" },
          { text: "Email", value: "email" },
          { text: "College", value: "college" },
          { text: "User Type", sortable: false },
          { text: "Status" },
          { text: "Created Date", value: "created_at" },
          { text: "Last Modified Date" },
          { text: "Actions", sortable: false },
        ],
        CHAIRPERSON: [
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Middle Name", value: "middle_name" },
          { text: "Email", value: "email" },
          { text: "College", value: "college" },
          { text: "Department", value: "department" },
          { text: "User Type", sortable: false },
          { text: "Status" },
          { text: "Created Date", value: "created_at" },
          { text: "Last Modified Date" },
          { text: "Actions", sortable: false },
        ],
        PROFESSOR: [
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Middle Name", value: "middle_name" },
          { text: "Email", value: "email" },
          { text: "College", value: "college" },
          { text: "Department", value: "department" },
          { text: "User Type", sortable: false },
          { text: "Status" },
          { text: "Created Date", value: "created_at" },
          { text: "Last Modified Date" },
          { text: "Actions", sortable: false },
        ],
        STUDENT: [
          { text: "First Name", value: "first_name" },
          { text: "Last Name", value: "last_name" },
          { text: "Middle Name", value: "middle_name" },
          { text: "Email", value: "email" },
          { text: "College", value: "college" },
          { text: "Department", value: "department" },
          { text: "Year", value: "year" },
          { text: "Section", value: "section" },
          { text: "User Type", sortable: false },
          { text: "Status" },
          { text: "Created Date", value: "created_at" },
          { text: "Last Modified Date" },
          { text: "Actions", sortable: false },
        ],
      },
      rolesID: {
        ADMIN: 1,
        DEAN: 2,
        CHAIRPERSON: 3,
        PROFESSOR: 4,
        STUDENT: 5,
      },
    };
  },
  computed: {
    ...mapGetters(["USERS", "USER_DETAILS"]),
  },
  methods: {
    MakeActive(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You are about to Activate this Account!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, submit it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              user_details_id: item.user_details_id,
            };
            this.$store.dispatch("MakeActive", payload).then((response) => {
              if (response === "success") {
                this.fetchTable();
                this.$swal.fire({
                  title: "Success!",
                  text: "Account Activated",
                  icon: "success",
                });
              }
            });
          }
        });
    },
    MakeInactive(item) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You are about to Deactivate this Account!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, submit it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              user_details_id: item.user_details_id,
            };
            this.$store.dispatch("MakeInactive", payload).then((response) => {
              if (response === "success") {
                this.fetchTable();
                this.$swal.fire({
                  title: "Success!",
                  text: "Account deactivated",
                  icon: "success",
                });
              }
            });
          }
        });
    },
    viewItem(item) {
      const payload = {
        params: {
          user_id: item.user_id,
        },
      };
      this.$store.dispatch("GetUserByUserId", payload).then(() => {
        this.$store.commit("USER_OPERATION", "View");
      });
    },
    editItem(item) {
      const payload = {
        params: {
          user_id: item.user_id,
        },
      };
      this.$store.dispatch("GetUserByUserId", payload).then(() => {
        this.$store.commit("USER_OPERATION", "Update");
      });
    },
    triggerOperation(item) {
      this.$store.commit("USER_OPERATION", "Create");
    },
    formattedDate(data) {
      return moment(data).format("MMMM D, YYYY - hh:mm A");
    },
    searchInput() {
      this.fetchTable();
    },
    async fetchTable() {
      const payload = {
        params: {
          college_id: this.USER_DETAILS.college_id,
          department_id: this.USER_DETAILS.department_id,
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          roleId: this.rolesID[this.sidenavViewer],
          role_ids: JSON.stringify(this.USER_DETAILS.user_role_details),
        },
      };
      await this.$store.dispatch("FetchUserData", payload).then(() => {
        this.total = this.USERS.total;
        this.loading = false;
      });
    },
    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.fetchTable();
    },
  },
  mounted() {
    // console.log(this.USERS.data)
  },
};
</script>

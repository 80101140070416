<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Colleges</p>
        <colleges_table />
        <colleges_dialog v-if="Get_Colleges_Dialog_Status == true" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import colleges_table from "@/views/table/colleges_table.vue";
import colleges_dialog from "@/views/dialog/Colleges_dialog.vue";
import { mapGetters } from "vuex";
export default {
  components: { colleges_table, colleges_dialog },
  computed: {
    ...mapGetters([
      "Get_Colleges_Dialog_Status",
      "Get_Colleges_Dialog_State_Mode",
    ]),
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

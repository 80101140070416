import api from '@/api/index'

export default {
  state: {
    COLLEGE_DROPDOWN: [],
    DEPARTMENT_DROPDOWN: [],
    ROLE_DROPDOWN: [],
    USED_YEAR: [],
  },

  getters: {
    COLLEGE_DROPDOWN: (state) => state.COLLEGE_DROPDOWN,
    DEPARTMENT_DROPDOWN: (state) => state.DEPARTMENT_DROPDOWN,
    ROLE_DROPDOWN: (state) => state.ROLE_DROPDOWN,
    USED_YEAR: (state) => state.USED_YEAR,
  },

  mutations: {
    COLLEGE_DROPDOWN: (state, data) => { state.COLLEGE_DROPDOWN = data },
    DEPARTMENT_DROPDOWN: (state, data) => { state.DEPARTMENT_DROPDOWN = data },
    ROLE_DROPDOWN: (state, data) => { state.ROLE_DROPDOWN = data },
    USED_YEAR: (state, data) => { state.USED_YEAR = data },
  },

  actions: {
    GetCollegeDropdown({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetCollegeDropdown').then((response) => {
          commit('COLLEGE_DROPDOWN', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetDepartmentDropdown({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetDepartmentDropdown').then((response) => {
          commit('DEPARTMENT_DROPDOWN', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetDepartmentDropdownByCollegeId({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetDepartmentDropdownByCollegeId',payload).then((response) => {
          commit('DEPARTMENT_DROPDOWN', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetRoleDropdown({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetRoleDropdown').then((response) => {
          commit('ROLE_DROPDOWN', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetAllUsedYear({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetAllUsedYear').then((response) => {
          commit('USED_YEAR', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    
  }
}
import api from '@/api/index'
export default {
  state: {
    dialog:
    {
      value: false,
      mode: null
    },
    researchValues: [],
    categoryValues: [],
    deptValues: [],
    edtResearchVal: [],
    viewResearchVal: []
  },
  // global variables for vuex of department
  getters: {
    GET_DIALOG_VAL_LIST: (state) => state.dialog.value,
    GET_DIALOG_MODE_LIST: (state) => state.dialog.mode,
    GET_RESEARCH_TABLE_VAL: (state) => state.researchValues,
    GET_CATEGORY_LIST_VAL: (state) => state.categoryValues,
    GET_DEPARTMENT_LIST_VAL: (state) => state.deptValues,
    GET_EDIT_LIST_VAL: (state) => state.edtResearchVal,
    GET_VIEW_LIST_VAL: (state) => state.viewResearchVal,
  },

  mutations: {
    set_dialog_val_list: (state, data) => { state.dialog.value = data },
    set_dialog_mode_list: (state, data) => { state.dialog.mode = data },
    SET_RESEARCH_TABLE_VAL: (state, data) => { state.researchValues = data },
    SET_CATEGORY_LIST_VAL: (state, data) => { state.categoryValues = data },
    SET_DEPARTMENT_LIST_VAL: (state, data) => { state.deptValues = data },
    SET_EDIT_LIST_VAL: (state, data) => { state.edtResearchVal = data },
    SET_VIEW_LIST_VAL: (state, data) => { state.viewResearchVal = data },
  },

  actions: {
    // commit mutations
    getAllResearchVals({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/showResearch', payload).then((response) => {
          // pag commit mutations toh
          // console.log(response);
          commit('SET_RESEARCH_TABLE_VAL', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    getAllResearchNoPaginate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/showResearch', payload).then((response) => {
          resolve(response.data.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    getAllCategoryVals({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/showCategories').then((response) => {
          // pag commit mutations toh
          // console.log(response);
          commit('SET_CATEGORY_LIST_VAL', response.data.categories)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    getAllDeptVals({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetDepartmentDropdown').then((response) => {
          // pag commit mutations toh
          // console.log(response);
          commit('SET_DEPARTMENT_LIST_VAL', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    showDependentTagsDepartment({ commit }, payload) {
      const requestData = {
        category_ids: payload, // Replace 'keyName' with the desired key name
      };

      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/showDependentTag', requestData).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    addResearch({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/addResearch', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    updateResearch({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/updateResearch', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    deleteResearch({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/deleteResearch', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    restoreResearch({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/restoreResearch', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },


    exportPdf({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const requestData = { fileName: payload };

        api.post(`api/exportFile`, requestData, { responseType: 'blob' })
          .then(response => {
            // console.log(response.data)
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            // commit('setPdfUrl', url); // Assuming you have a mutation to store the PDF URL
            resolve(url);
          })
          .catch(error => {
            reject(error);
          });
      });
    }

  }
}
<template>
  <v-card outlined class="edit-research">
    <p class="font-weight-bold">Edit Research</p>
    <v-form ref="researchForm">
      <v-row>
        <input type="hidden" v-model="payload.id" />
        <v-col cols="12">
          <v-text-field v-model="payload.edtTitle" label="Title" outlined hide-details="auto"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="payload.edtAbstract" label="Abstract" outlined hide-details="auto"></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="3">
          <v-menu v-model="datePickerMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field prepended="true" v-model="payload.edtPublication_year" label="Publication Date" readonly
                v-on="on" outlined hide-details="auto"></v-text-field>
            </template>
            <v-date-picker type="month" v-model="payload.edtPublication_year"
              @input="datePickerMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-menu v-model="presentationDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="payload.edtPresentation_date" label="Presentation Date" readonly v-on="on" outlined
                hide-details="auto"></v-text-field>
            </template>
            <v-date-picker v-model="payload.edtPresentation_date" no-title :type="'date'"
              @input="presentationDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field v-model="payload.edtPresentation_location" label="Presentation Location" outlined
            hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-menu v-model="submissionDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on }">
              <v-text-field v-model="payload.edtSubmission_date" label="Submission Date" readonly v-on="on" outlined
                hide-details="auto"></v-text-field>
            </template>
            <v-date-picker v-model="payload.edtSubmission_date" no-title
              @input="submissionDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea v-model="payload.edtDetails" label="Intellectual Property Details" outlined
            hide-details="auto"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-autocomplete v-model="payload.edt_funding" :items="items" label="funding"
             outlined hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.edtCategoryIds" :items="categoryOptions" item-text="name" item-value="id"
            label="Category" multiple @change="handleCategoryChange" outlined hide-details="auto"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.edtTagIds" :items="tagOptions" item-text="name" item-value="id" label="Tag"
            multiple outlined hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field v-model="payload.edtAuthors" label="Authors" outlined hide-details="auto"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete v-model="payload.edtDeptId" :items="deptOptions" item-text="name" item-value="id"
            label="Department" outlined hide-details="auto"></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col cols="12">
          <v-file-input v-model="payload.edt_pdf_file" label="PDF File" @change="onFileChange" accept=".pdf" outlined
            hide-details="auto"></v-file-input>
        </v-col>
      </v-row>

      <v-btn color="#820909" @click="cancel" dark class="mr-1">Cancel</v-btn>
      <v-btn color="#002147" @click="updateResearch()" dark>Submit</v-btn>
    </v-form>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      items: ['Internal', 'External'],
      submissionDateMenu: false,
      datePickerMenu: false,
      presentationDateMenu: false,
      categoryOptions: [], // Fill with actual category data
      tagOptions: [],
      deptOptions: [],
      payload: {
        id: null,
        edt_pdf_file: null,
        edt_funding: null,
        edtTitle: "",
        edtAbstract: "",
        edtPublication_year: null,
        edtPresentation_date: null,
        edtPresentation_location: "",
        edtSubmission_date: null,
        edtDetails: "",
        edtCategoryIds: [],
        edtTagIds: [],
        edtAuthors: "",
        edtDeptId: null,
      },
    };
  },
  methods: {
    validatePayload(payload) {
      const requiredKeys = [
        "edtTitle",
        "edtAbstract",
        "edt_funding",
        "edtPublication_year",
        "edtPresentation_date",
        "edtPresentation_location",
        "edtSubmission_date",
        "edtDetails",
        "edtCategoryIds",
        "edtTagIds",
        "edtAuthors",
        "edtDeptId",
      ];

      for (const key of requiredKeys) {
        if (payload[key] === null || payload[key] === "") {
          return false; // Validation failed
        }
      }

      return true; // Validation passed
    },
    updateResearch() {
      const isValid = this.validatePayload(this.payload);
      // console.log(this.payload)
      if (isValid) {
        Swal.fire({
          text: "Are you sure to edit this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData();
            for (const key in this.payload) {
              if (this.payload[key] !== null) {
                // Check if the key is either 'category_ids' or 'tag_ids'
                if (key === "edtCategoryIds" || key === "edtTagIds") {
                  if (typeof this.payload[key] === "string") {
                    const idsArray = this.payload[key]
                      .split(",")
                      .map((item) => item.trim());
                    formData.append(key, JSON.stringify(idsArray));
                  } else {
                    formData.append(key, JSON.stringify(this.payload[key]));
                  }
                } else {
                  formData.append(key, this.payload[key]);
                }
              }
            }
            // console.log(this.payload)
            this.$store
              .dispatch("updateResearch", formData)
              .then((response) => {
                Swal.fire(response.title, response.message, response.icon);
                if (response.status == "success") {
                this.$store.commit("SET_EDIT_LIST_VAL", this.payload);
                // console.log(response)
                this.$store.commit("set_dialog_val_list", false); // mutation name
                this.$store.commit("set_dialog_mode_list", null); // mutation name
                this.$store.dispatch("getAllResearchVals").then(() => { });
                }
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "All Fields Are Required.",
        });
      }
    },
    onFileChange(event) {
      if (event.target && event.target.files.length > 0) {
        // Get the selected file
        this.payload.edt_pdf_file = event.target.files[0];
      } else {
        // Handle the case where no file is selected
        // You can display an error message or take other appropriate action
        console.log("no file");
      }
    },
    cancel() {
      this.$store.commit("set_dialog_val_list", false); // mutation name
      this.$store.commit("set_dialog_mode_list", null); // mutation name
    },
    handleCategoryChange() {
      // alert(this.payload.category_ids)
      this.$store
        .dispatch("showDependentTagsDepartment", this.payload.edtCategoryIds)
        .then((response) => {
          // console.log(response)
          this.tagOptions = response;
          this.payload.edtTagIds = null;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  computed: {
    yearFormat() {
      // Set the format to display only the year
      return "yyyy";
    },
    ...mapGetters([
      "GET_DIALOG_VAL_LIST",
      "GET_DIALOG_MODE_LIST",
      "GET_COLLEGE_TABLE_VAL",
      "GET_CATEGORY_LIST_VAL",
      "GET_DEPARTMENT_LIST_VAL",
      "GET_EDIT_LIST_VAL",
    ]),
  },
  mounted() {
    // console.log(this.GET_CATEGORY_LIST_VAL)
    this.categoryOptions = this.GET_CATEGORY_LIST_VAL;
    this.$store.dispatch("getAllDeptVals").then(() => {
      // console.log(this.GET_COLLEGE_TABLE_VAL)
      this.deptOptions = this.GET_DEPARTMENT_LIST_VAL;
    });
    // console.log(this.GET_EDIT_LIST_VAL)
    // TO HAVE VALUES ON THE EDIT
    this.payload.edtTitle = this.GET_EDIT_LIST_VAL.research_title;
    this.payload.edtAbstract = this.GET_EDIT_LIST_VAL.research_abstract;
    this.payload.edtPublication_year =
      this.GET_EDIT_LIST_VAL.research_publication_year;
    this.payload.edtPresentation_date =
      this.GET_EDIT_LIST_VAL.research_presentation_date;
    this.payload.edtPresentation_location =
      this.GET_EDIT_LIST_VAL.research_presentation_location;
    this.payload.edtSubmission_date =
      this.GET_EDIT_LIST_VAL.research_submission_date;
    this.payload.edtDetails = this.GET_EDIT_LIST_VAL.research_details;
    this.payload.edtCategoryIds = this.GET_EDIT_LIST_VAL.research_category_ids;
    this.$store
      .dispatch("showDependentTagsDepartment", this.payload.edtCategoryIds)
      .then((response) => {
        this.tagOptions = response;
        // Access the id property of the response object and assign it to this.edtTagIds
        // Rest of your code
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    this.payload.edtTagIds = this.GET_EDIT_LIST_VAL.research_tag_ids;
    this.payload.edtAuthors = this.GET_EDIT_LIST_VAL.research_authors;
    this.deptOptions = this.GET_DEPARTMENT_LIST_VAL;
    this.payload.edtDeptId = this.GET_EDIT_LIST_VAL.research_dept_id;
    this.payload.id = this.GET_EDIT_LIST_VAL.id;
    this.payload.edt_funding = this.GET_EDIT_LIST_VAL.funding;
  },
};
</script>

<style scoped>
.edit-research {
  padding: 1rem;
}
</style>

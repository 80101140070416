import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VTextField,{attrs:{"label":"Search","single-line":"","dense":"","hide-details":"auto","solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchInput()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"9"}},[(_vm.sidenavViewer !== 'STUDENT')?_c(VBtn,{attrs:{"color":"#002147","dark":""},on:{"click":function($event){return _vm.triggerOperation()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Create New User")],1):_vm._e()],1)],1),_c(VDataTable,{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers[_vm.sidenavViewer],"items":_vm.USERS.data,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions,
    }},on:{"update:options":[function($event){_vm.options=$event},_vm.handlePaginationChange]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.first_name))]),_c('td',[_vm._v(_vm._s(item.last_name))]),_c('td',[_vm._v(_vm._s(item.middle_name))]),_c('td',[_vm._v(_vm._s(item.email))]),(_vm.sidenavViewer != 'ADMIN')?_c('td',[_vm._v(_vm._s(item.college))]):_vm._e(),(
            _vm.sidenavViewer === 'PROFESSOR' ||
            _vm.sidenavViewer === 'CHAIRPERSON' ||
            _vm.sidenavViewer === 'STUDENT'
          )?_c('td',[_vm._v(" "+_vm._s(item.department)+" ")]):_vm._e(),(_vm.sidenavViewer === 'STUDENT')?_c('td',[_vm._v(" "+_vm._s(item.year)+" ")]):_vm._e(),(_vm.sidenavViewer === 'STUDENT')?_c('td',[_vm._v(" "+_vm._s(item.section)+" ")]):_vm._e(),_c('td',_vm._l((item.roles),function(role){return _c(VChip,{key:role.id},[_vm._v(" "+_vm._s(role.name)+" ")])}),1),_c('td',[_c(VChip,{attrs:{"color":item.status === 'Active' ? 'success' : 'error'}},[_vm._v(_vm._s(item.status))])],1),_c('td',[_vm._v(_vm._s(_vm.formattedDate(item.created_at)))]),_c('td',[_vm._v(_vm._s(_vm.formattedDate(item.updated_at)))]),_c('td',[_c(VIcon,{on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v("mdi-eye")]),_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
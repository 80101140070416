<template>
  <v-app style="background: #e2e5f4">
    <v-form ref="form" @submit.prevent="submit">
      <v-container class="container">
        <v-card class="bg-card">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.oldPassword"
                :rules="rules.password"
                :type="show ? 'text' : 'password'"
                label="Old Password"
                required
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.password0"
                :rules="rules.password"
                :type="show2 ? 'text' : 'password'"
                label="New Password"
                required
                outlined
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mb-1">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="form.newPassword"
                :rules="rules.password"
                :type="show3 ? 'text' : 'password'"
                label="Confirm Password"
                required
                outlined
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show3 = !show3"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn type="submit" color="#002147" dark>Submit</v-btn>
        </v-card>
      </v-container>
    </v-form>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      genders: [{ name: "Male" }, { name: "Female" }, { name: "Others" }],
      file: null,
      show: false,
      show2: false,
      show3: false,
      imageUrl: require("../../assets/sample.jpg"),
      form: {
        user_id: null,
        oldPassword: null,
        password0: null,
        newPassword: null,
      },
      status: [{ name: "Active" }, { name: "Inactive" }],
      rules: {
        password: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && v.length >= 8) ||
            "Min 8 characters",
        ],
        required: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
        ],
        min: [(v) => v.length >= 8 || "Min 8 characters"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        contact: [
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
          (v) =>
            (v !== null && v !== undefined && v.length >= 11) ||
            "Min 11 characters",
          (v) =>
            (v !== null && v !== undefined && v.length <= 11) ||
            "Max 11 characters",
        ],
        age: [
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["USER_DETAILS"]),
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        if (this.form.password0 != this.form.newPassword) {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Your password does not match!",
          });
          return;
        }

        const payload = this.form;

        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You are about to submit the form!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch("UpdatePassword", payload)
                .then((response) => {
                  if (response === "success") {
                    this.$swal.fire({
                      title: "Success!",
                      text: "Update password successfully!",
                      icon: "success",
                    });
                    this.$router.push("/USER_PROFILE");
                  } else {
                    this.$swal.fire({
                      title: "Validation Error",
                      text: response,
                      icon: "warning",
                    });
                  }
                });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

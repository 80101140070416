<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field v-model="search" label="Search" single-line dense hide-details="auto"
          @keydown.enter="research_searchInput()" solo>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="get_research_prof_table_val.data" :options.sync="options"
      :items-per-page="itemsPerPage" :server-items-length="total" class="elevation-1 mt-5"
      @update:options="handlePaginationChange" :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.full_name }}</td>
          <td>{{ item.research_title }}</td>
          <!-- <td>{{ item.research_abstract }}</td> -->
          <!-- <td>{{ item.research_publication_year }}</td> -->
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import store from "@/store";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
// import PDFViewer from 'pdf-viewer';

export default {
  data() {
    return {
      //handle tables
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: [
        // { text: "ID", value: "id" },
        { text: "Professor Name", value: "user", sortable: false },
        // { text: "Abstract", value: "research_abstract" },
        { text: "Research Title", value: "research_title" }
        // { text: "Presentation Date", value: "research_presentation_date" }
        // {
        //   text: "Presentation Location",
        //   value: "research_presentation_location",
        // }
        // { text: "Intellectual Property Detail", value: "research_details" },
        // { text: "Submission Date", value: "research_submission_date" },
        // { text: "Category", value: "relatedCategories", sortable: false },
        // { text: "Created By", value: "full_name" },
        // { text: "Departments", value: "research_dept_name" },
        // { text: "Tags", value: "status", sortable: false },
        // { text: "Status", value: "status", sortable: false },
        // { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    handlePaginationChange() {
      // console.log(this.options)
    },
    research_searchInput() {
      this.research_fetchTable();
    },

    async research_fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      await this.$store.dispatch("getAllResearchWithProfVals", payload).then(() => {
        this.total = this.get_research_prof_table_val.total;
        // this.loading = false
      });
    },

    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.research_fetchTable(); // Corrected method name
    },
  },
  computed: {
    ...mapGetters([
      "get_research_prof_table_val",
    ]),
  },
  mounted() {
    console.log(this.get_research_prof_table_val);
  },
};
</script>

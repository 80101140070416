<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-card class="create-user" outlined>
      <p class="font-weight-bold">{{ USER_OPERATION }} Users</p>
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="form.user_role_details_ids"
            :items="ROLE_DROPDOWN"
            @change="handleRoleChange"
            :rules="rules.required"
            :readonly="
              isChairpersonOnly ||
              USER_OPERATION === 'View' ||
              SELECTED_USER?.user_id === 1 || 
              sidenavViewer === 'STUDENT'
            "
            multiple
            item-text="name"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Roles"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-if="showCollegeDropdown"
            v-model="form.college_id"
            :items="COLLEGE_DROPDOWN"
            @change="handleCollegeChange"
            :rules="rules.required"
            :readonly="
              isDeanOnly || isChairpersonOnly || USER_OPERATION === 'View'
            "
            item-text="name"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Colleges"
            hide-details="auto"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :disabled="isCollegeItemDisabled(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-if="showDepartmentDropdown"
            v-model="form.department_id"
            :items="DEPARTMENT_DROPDOWN"
            item-text="name"
            :rules="rules.required"
            :readonly="isChairpersonOnly || USER_OPERATION === 'View'"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Department"
            hide-details="auto"
          >
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :disabled="isDepartmentItemDisabled(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.first_name"
            :rules="rules.required"
            outlined
            :readonly="USER_OPERATION === 'View'"
            label="First Name"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.middle_name"
            outlined
            :readonly="USER_OPERATION === 'View'"
            label="Middle Name"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.last_name"
            :rules="rules.required"
            outlined
            label="Last Name"
            :readonly="USER_OPERATION === 'View'"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.email"
            :rules="rules.email"
            outlined
            label="Email"
            :readonly="USER_OPERATION === 'View'"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="form.gender"
            :items="genders"
            item-text="name"
            item-value="name"
            :readonly="USER_OPERATION === 'View'"
            :rules="rules.required"
            auto-select-first
            chips
            outlined
            label="Gender"
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="form.status"
            :items="status"
            item-text="name"
            item-value="name"
            :readonly="
              USER_OPERATION != 'Update' || SELECTED_USER?.user_id === 1
            "
            :rules="rules.required"
            auto-select-first
            chips
            outlined
            label="Status"
            hide-details="auto"
          ></v-autocomplete>
          <!-- <v-text-field v-model="form.age" :rules="rules.age" outlined dense label="Age"
                                :readonly="USER_OPERATION === 'View'" hide-details="auto" class="mb-4"></v-text-field> -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="form.address"
            :rules="rules.required"
            outlined
            label="Address"
            :readonly="USER_OPERATION === 'View'"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="form.username"
            :rules="rules.required"
            outlined
            label="Username"
            :readonly="
              USER_OPERATION === 'View' || SELECTED_USER?.user_id === 1
            "
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-if="this.USER_OPERATION == 'Create'"
            v-model="form.password"
            :rules="rules.password"
            :type="show ? 'text' : 'password'"
            label="Password"
            required
            outlined
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn @click="closeOperation()" color="#820909" dark class="mr-2"
        >Cancel</v-btn
      >
      <v-btn v-if="USER_OPERATION != 'View'" color="#002147" type="submit" dark
        >Submit</v-btn
      >
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    sidenavViewer: String,
  },
  data() {
    return {
      genders: [{ name: "Male" }, { name: "Female" }, { name: "Others" }],
      rolesID: {
        ADMIN: 1,
        DEAN: 2,
        CHAIRPERSON: 3,
        PROFESSOR: 4,
      },
      show: true,
      form: {
        user_id: null,
        username: null,
        password: null,
        age: null,
        status: "Active",
        gender: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        address: null,
        department_id: null,
        college_id: null,
        user_role_details_ids: [],
      },
      status: [{ name: "Active" }, { name: "Inactive" }],
      rules: {
        password: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && v.length >= 8) ||
            "Min 8 characters",
        ],
        required: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
        ],
        min: [(v) => v.length >= 8 || "Min 8 characters"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        contact: [
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
          (v) =>
            (v !== null && v !== undefined && v.length >= 11) ||
            "Min 11 characters",
          (v) =>
            (v !== null && v !== undefined && v.length <= 11) ||
            "Max 11 characters",
        ],
        age: [
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
        ],
      },
    };
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        const payload = this.form;
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You are about to submit the form!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (this.USER_OPERATION === "Create") {
                this.$store.dispatch("StoreUser", payload).then((response) => {
                  if (response === "success") {
                    this.$swal.fire({
                      title: "Success!",
                      text: "Form submitted successfully!",
                      icon: "success",
                    });
                    this.$store.commit("USER_OPERATION", null);
                  } else {
                    this.$swal.fire({
                      title: "Validation Error",
                      text: response,
                      icon: "warning",
                    });
                  }
                });
              }
              if (this.USER_OPERATION === "Update") {
                this.$store.dispatch("UpdateUser", payload).then((response) => {
                  if (response === "success") {
                    this.$swal.fire({
                      title: "Success!",
                      text: "Form submitted successfully!",
                      icon: "success",
                    });
                    if (
                      this.SELECTED_USER.user_id == this.USER_DETAILS.user_id
                    ) {
                      window.location.reload();
                    }
                    this.$store.commit("USER_OPERATION", null);
                  } else {
                    this.$swal.fire({
                      title: "Validation Error",
                      text: response,
                      icon: "warning",
                    });
                  }
                });
              }
            }
          });
      }
    },

    closeOperation() {
      this.$store.commit("USER_OPERATION", null);
      this.$store.commit("SELECTED_USER", null);
    },

    handleCollegeChange() {
      this.GetDepartmentDropdown();
      this.form.department_id = null;
    },

    handleRoleChange(item) {
      if (item.includes(2)) {
        this.form.college_id = null;
      }
      if (item.includes(3)) {
        this.form.department_id = null;
      }
    },

    // ROLE DROPDOWN
    async GetRoleDropdown() {
      //GET ALL ROLE THEN ASSIGN THE ITEMS IN ROLEDROPDOWN
      await this.$store.dispatch("GetRoleDropdown").then(() => {
        //IF ITS DEAN THEN FILTER IT
        if (this.isDean) {
          const filteredRoleDropdown = this.ROLE_DROPDOWN.filter((item) => {
            return item.id === 3 || item.id === 4;
          });
          this.$store.commit("ROLE_DROPDOWN", filteredRoleDropdown);
        }
        // remove student if not student
        if (this.sidenavViewer !== "STUDENT") {
          const filteredRoleDropdown = this.ROLE_DROPDOWN.filter((item) => {
            return item.id !== 5;
          });
          this.$store.commit("ROLE_DROPDOWN", filteredRoleDropdown);
        }
      });
    },

    // COLLEGE DROPDOWN
    async GetCollegeDropdown() {
      await this.$store.dispatch("GetCollegeDropdown").then((response) => {
        console.log(response);
      });
    },

    //DEPARTMENT DROPDOWN
    async GetDepartmentDropdown() {
      let payload = { params: { college_id: null } };
      if (this.isAdmin) {
        payload.params.college_id = this.form.college_id;
      } else {
        payload.params.college_id = this.USER_DETAILS.college_id;
      }
      await this.$store.dispatch("GetDepartmentDropdownByCollegeId", payload);
    },

    isCollegeItemDisabled(item) {
      // console.log(item)
      if (this.USER_OPERATION === "Create") {
        if (this.form.user_role_details_ids.includes(2)) {
          return item.currentDean != null;
        }
      } else if (this.USER_OPERATION === "Update") {
        if (this.form.user_role_details_ids.includes(2)) {
          return (
            item.currentDean != null &&
            this.SELECTED_USER.id != item.currentDean.user_id
          );
        }
      }
    },

    isDepartmentItemDisabled(item) {
      console.log(item);
      if (this.USER_OPERATION === "Create") {
        if (this.form.user_role_details_ids.includes(3)) {
          return item.currentChairperson != null;
        }
      } else if (this.USER_OPERATION === "Update") {
        if (this.form.user_role_details_ids.includes(3)) {
          return (
            item.currentChairperson != null &&
            this.SELECTED_USER.id != item.currentChairperson.user_id
          );
        }
      }
    },
  },

  watch: {
    "form.college_id": {
      handler(val) {
        this.GetDepartmentDropdown();
      },
    },
  },

  computed: {
    ...mapGetters([
      "USER_OPERATION",
      "COLLEGE_DROPDOWN",
      "DEPARTMENT_DROPDOWN",
      "ROLE_DROPDOWN",
      "USER_DETAILS",
      "SELECTED_USER",
    ]),
    //if Logged in user is admin
    isAdmin() {
      return this.USER_DETAILS.user_role_details.some((item) => item.id === 1);
    },
    //if Logged in user is dean
    isDean() {
      return this.USER_DETAILS.user_role_details.some((item) => item.id === 2);
    },
    isDeanOnly() {
      return (
        this.USER_DETAILS.user_role_details.some((item) => item.id === 2) &&
        this.USER_DETAILS.user_role_details.length == 1
      );
    },
    //if Logged in user is chairperson
    isChairperson() {
      return this.USER_DETAILS.user_role_details.some((item) => item.id === 3);
    },
    isChairpersonOnly() {
      return (
        this.USER_DETAILS.user_role_details.some((item) => item.id === 3) &&
        this.USER_DETAILS.user_role_details.length == 1
      );
    },

    //condition to show college dropdown
    showCollegeDropdown() {
      if (this.isSelectedUserAdminOnly) return false;
      else return true;
    },

    //condition to show department dropdown
    showDepartmentDropdown() {
      if (
        this.isSelectedUserAdminOnly ||
        this.isSelectedUserDeanOnly ||
        this.isSelectedUserAdminAndDeanOnly
      )
        return false;
      else return true;
    },

    //checking of selected user role
    isSelectedUserAdminOnly() {
      return (
        this.form.user_role_details_ids.includes(1) &&
        this.form.user_role_details_ids.length === 1
      );
    },
    isSelectedUserDeanOnly() {
      return (
        this.form.user_role_details_ids.includes(2) &&
        this.form.user_role_details_ids.length === 1
      );
    },
    isSelectedUserAdminAndDeanOnly() {
      return (
        this.form.user_role_details_ids.includes(1) &&
        this.form.user_role_details_ids.includes(2) &&
        this.form.user_role_details_ids.length === 2
      );
    },
    isSelectedUserChairpersonOnly() {
      return (
        this.form.user_role_details_ids.includes(3) &&
        this.form.user_role_details_ids.length === 1
      );
    },
    isSelectedUserProfessorOnly() {
      return (
        this.form.user_role_details_ids.includes(4) &&
        this.form.user_role_details_ids.length === 1
      );
    },

    isSelectedUserChairpersonAndProfessor() {
      return (
        this.form.user_role_details_ids.includes(4) &&
        this.form.user_role_details_ids.includes(3) &&
        this.form.user_role_details_ids.length === 2
      );
    },

    // generating password by current date
    generatedPassword() {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const formattedDay = String(day).padStart(2, "0");
      const formattedMonth = String(month).padStart(2, "0");
      return `${year}${formattedMonth}${formattedDay}`;
    },
  },

  beforeDestroy() {
    this.closeOperation();
  },

  //dropdown items init
  created() {
    this.GetRoleDropdown();
    this.GetCollegeDropdown();
    this.GetDepartmentDropdown();
  },

  // assigning of form value
  mounted() {
    if (this.USER_OPERATION !== "View") {
      this.form.password = this.generatedPassword;
    }
    //Creating new User
    if (this.USER_OPERATION === "Create") {
      this.form.user_role_details_ids = [this.rolesID[this.sidenavViewer]];
      this.form.user_role_details_ids = [this.rolesID[this.sidenavViewer]];
      this.form.user_role_details_ids = [this.rolesID[this.sidenavViewer]];
      this.form.password = this.generatedPassword;
      if (
        this.sidenavViewer === "CHAIRPERSON" ||
        this.sidenavViewer === "PROFESSOR"
      ) {
        // if Logged in user is dean or Chairperson
        if (this.isDean || this.isChairperson) {
          this.form.college_id = this.USER_DETAILS.college_id;
        }
        if (this.isChairperson) {
          this.form.department_id = this.USER_DETAILS.department_id;
        }
      }
    }
    //Updating and Viewing of user
    if (this.USER_OPERATION === "Update" || this.USER_OPERATION === "View") {
      this.form.user_role_details_ids =
        this.SELECTED_USER.user_role_details.map((item) => item.id);
      this.form.college_id = this.SELECTED_USER.college_id;
      this.form.department_id = this.SELECTED_USER.department_id;
      this.form.first_name = this.SELECTED_USER.first_name;
      this.form.middle_name = this.SELECTED_USER.middle_name;
      this.form.last_name = this.SELECTED_USER.last_name;
      this.form.email = this.SELECTED_USER.email;
      this.form.gender = this.SELECTED_USER.gender;
      this.form.age = this.SELECTED_USER.age;
      this.form.status = this.SELECTED_USER.status;
      this.form.username = this.SELECTED_USER.username;
      this.form.address = this.SELECTED_USER.address;
      this.form.user_id = this.SELECTED_USER.user_id;
    }
  },
};
</script>

<style scoped>
.create-user {
  padding: 1rem;
}
</style>

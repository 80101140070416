<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Professor List</p>
        <Tables_prof />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Tables_prof from "../../../views/table/prof_list_table.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Tables_prof,
  },
  computed: {
    ...mapGetters(["USER_DETAILS"]),
  },
  mounted() {},
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Categories</p>
        <CategoryTale />
        <CategoryDialog v-if="Get_Dialog_Status == true" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import CategoryTale from "@/views/table/category_table.vue";
import CategoryDialog from "@/views/dialog/Category_dialog.vue";
import { mapGetters } from "vuex";
export default {
  components: { CategoryTale, CategoryDialog },
  computed: {
    ...mapGetters(["Get_Dialog_State_Mode", "Get_Dialog_Status"]),
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

import api from '@/api/index'

export default {
  state: {
    Category: [],
    mode: null,
    role: null,
    status: false,
    SingleCategory: [],
    searchQuery: "",
    loading: false,
    departmentOptions: [], 
    Is_Add: false,
  },

  getters: {
    Get_Category: (state) => state.Category,
    Get_Dialog_Status: (state) => state.status,
    Get_role: (state) => state.role,
    Get_Dialog_State_Mode: (state) => state.mode,
    Get_Single_Category: (state) => state.SingleCategory,
    Get_SearchQuery: (state) => state.searchQuery, // Corrected the getter name
    isLoading: (state) => state.loading,
    Get_dept_options: (state) => state.departmentOptions,
    Get_add: (state) => state.Is_Add,
  },

  mutations: {
    Set_Category: (state, data) => {
      state.Category = data;
    },
    SET_DIALOG_STATUS: (state, data) => {
      state.status = data;
    },
    SET_ROLE: (state, data) => {
      state.role = data;
    },
    SET_DIALOG_STATE_MODE: (state, data) => {
      state.mode = data;
    },
    Set_Single_Category: (state, data) => {
      state.SingleCategory = data;
    },
    Set_SearchQuery(state, data) {
      state.searchQuery = data;
    },
    Set_Loading(state, loading) {
      state.loading = loading;
    },
    Set_Department_Options: (state, data) => {
      state.departmentOptions = data;
    },
  
    Clear_Department_Options: (state) => {
      state.departmentOptions = [];
    },
    Set_add(state, data) {
      state.Is_Add  = data;
    },
  },

  actions: {

    Category({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/CategoryFilter',payload).then((response) => {
          //console.log(response)
          commit('Set_Category', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    Role({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/getRole').then((response) => {
          //console.log(response)
          commit('SET_ROLE', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Single_Category({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get(`api/editCategories/${payload}`).then((response) => {
          // commit('Set_Single_Category', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Store_Category({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/addCategories`, payload).then((response) => {
          // commit('Set_Category', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Update_Category({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const { id, data } = payload;
        api.put(`api/updateCategories/${id}`, data).then((response) => {
          // commit('Set_Single_Category', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Delete_Category({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.delete(`api/deleteCategories/${payload}`).then((response) => {
          // commit('Set_Category', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Restore_Category({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/restoreCategory/${payload}`).then((response) => {
          // commit('Set_Category', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    Search_Category({ commit }, payload) {
      commit("Set_SearchQuery", payload);
      commit("Set_Loading", true);

      api.get(`api/searchCategory?search=${payload}`)
        .then((response) => {
          //console.log(response)
          commit("Set_Loading", false);
          commit("Set_Category", response.data);
        })
        .catch((error) => {
          commit("Set_Loading", false);
        });
    },

    FetchDepartmentOptions({ commit }, collegeId) {

      return new Promise((resolve, reject) => {
        api.get(`api/departments/${collegeId}`).then((response) => {
          commit("Set_Department_Options", response.data);
          resolve(response.data);
       }).catch((error) => {
         reject(error);
      });
      });
    },
  },
};

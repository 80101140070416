import api from '@/api/index'
export default {
  state: {
    dialog:
    {
      value: false,
      mode: null
    },
    deptValues: [],
    chairPersonDropDown: [],
    editChaipersonDropdown: [],
    selectedDepartment: [],
    collegeDropdown: [],
    IS_NEW_VAL_DEPT: false
  },
  // global variables for vuex of department
  getters: {
    GET_DIALOG_VAL: (state) => state.dialog.value,
    GET_DIALOG_MODE: (state) => state.dialog.mode,
    GET_DEPT_VAL: (state) => state.deptValues,
    GET_CHAIR_VAL: (state) => state.chairPersonDropDown,
    GET_EDT_CHAIR_VAL: (state) => state.editChaipersonDropdown,
    GET_EDT_DEPT_VAL: (state) => state.selectedDepartment,
    GET_COLLEGE_VAL: (state) => state.collegeDropdown,
    GET_IS_NEW_VAL_DEPT: (state) => state.IS_NEW_VAL_DEPT,
  },

  mutations: {
    set_dialog_val: (state, data) => { state.dialog.value = data },
    set_dialog_mode: (state, data) => { state.dialog.mode = data },
    SET_DEPT_VAL: (state, data) => { state.deptValues = data },
    SET_CHAIR_VAL: (state, data) => { state.chairPersonDropDown = data },
    SET_EDT_CHAIR_VAL: (state, data) => { state.editChaipersonDropdown = data },
    SET_EDT_DEPT_VAL: (state, data) => { state.selectedDepartment = data },
    SET_COLLEGE_VAL: (state, data) => { state.collegeDropdown = data },
    SET_IS_NEW_VAL_DEPT: (state, data) => { state.IS_NEW_VAL_DEPT  = data; }
  },

  actions: {
    // commit mutations
    getAllDeptVals({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/showDepartments', payload).then((response) => {
          // pag commit mutations toh
          // console.log(response);
          commit('SET_DEPT_VAL', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    getAllChairVals({ commit }, payload) {
      // Make sure payload is a valid value
      // console.log(payload);

      const requestData = {
        college_id: payload,
      };
      //resolve pag ka gusto mo direct ma fetch
      return new Promise((resolve, reject) => {
        api.post('api/showChairpersonsDropdown', requestData).then((response) => {
            // commit('SET_CHAIR_VAL', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });

    },

    getAllChairValsEdit({ commit }, payload) {
      // Make sure payload is a valid value
      // console.log(payload);

      const requestData = {
        college_id: payload,
      };
      //resolve pag ka gusto mo direct ma fetch
      // Assuming you have an API call
      return new Promise((resolve, reject) => {
        api.post('api/showChairpersonsEditDropdown', requestData).then((response) => {
            // commit('SET_CHAIR_VAL', response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    getAllCollegeVals({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetCollegeDropdown').then((response) => {
          // pag commit mutations toh
          // console.log(response.data)
          commit('SET_COLLEGE_VAL', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    //KAILANGAN TALAGA YUNG COMMIT NA UNANG PARAMETER
    addDepartment({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/addDepartment', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    editDepartment({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/editDepartment/{id}/edit', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    updateDepartment({ commit }, payload) {
      // console.log(payload)
      const { id, data } = payload;
      return new Promise((resolve, reject) => {
        api.put(`api/updateDepartment/${id}`, data).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },

    deleteDepartment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/deleteDepartment`, payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    
    restoreDepartment({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.post(`api/restoreDepartment`, payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    }


  }
}
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#f1f2f7"}},[_c(VContainer,{staticClass:"container"},[_c(VRow,{staticClass:"mt-5"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"graph",attrs:{"elevation":"0"}},[_c('div',{staticClass:"research-graph"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Researches by Departments")]),(!this.isProfs || this.isAdmin || this.isCoor || this.isDean)?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{attrs:{"elevation":"0","outlined":""}},[_c('apexchart',{attrs:{"type":"bar","options":_vm.chartOptionsCategories,"series":_vm.chartSeriesCategories}})],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{attrs:{"elevation":"0","outlined":""}},[_c('apexchart',{attrs:{"type":"bar","options":_vm.chartOptionsTags,"series":_vm.chartSeriesTags}})],1)],1)],1):_vm._e(),(!this.isProfs || this.isAdmin || this.isCoor || this.isDean )?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{attrs:{"elevation":"0","outlined":""}},[_c('apexchart',{attrs:{"type":"pie","options":_vm.chartOptionsPie,"series":_vm.chartSeriesPie}})],1)],1)],1):_vm._e()],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Departments</p>
        <Tables />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
//  NO PLURAL FOR NAMING IN VUE
import Tables from "@/views/tables/DepartmentTable.vue";
export default {
  components: { Tables },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

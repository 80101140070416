import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[(_vm.$route.meta.showSideMenuBar)?[_c(VNavigationDrawer,{attrs:{"app":"","dark":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('SideMenuBar')],1),_c(VAppBar,{staticStyle:{"background":"#ff7900"},attrs:{"app":"","elevation":"0","dark":""}},[_c(VIcon,{on:{"click":_vm.toggleSidebar}},[_vm._v("mdi-menu")]),_c('p',{staticClass:"ma-0 ml-2"},[_vm._v(_vm._s(_vm.$route.name))]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-auto",attrs:{"text":"","dark":""}},on),[_c('div',[_c('img',{staticClass:"mr-2 profile-picture",attrs:{"src":_vm.PROFILE_PATH,"alt":"Profile Picture","width":"50"}})]),_vm._v(" "+_vm._s(_vm.USER_DETAILS.full_name)+" ")])]}}],null,false,2987499253)},[_c(VList,[_c(VListItem,[_c(VBtn,{attrs:{"text":"","block":"","color":"primary"},on:{"click":function($event){return _vm.profile()}}},[_vm._v("Profile")])],1),_c(VListItem,[_c(VBtn,{attrs:{"text":"","block":"","color":"secondary"},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v("ChangePassword")])],1),_c(VListItem,[_c(VBtn,{attrs:{"text":"","block":"","color":"error"},on:{"click":function($event){return _vm.submitLogout()}}},[_vm._v("Logout")])],1)],1)],1)],1)]:_vm._e(),(_vm.routerViewVisible)?_c('router-view'):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details="auto"
          @keydown.enter="Category_searchInput()"
          solo
        >
        </v-text-field>
        <!-- <v-icon @click="Category_searchInput()">mdi-magnify</v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="9" class="d-flex align-center justify-end">
        <v-btn @click="addCategory()" color="#002147" dark>
          <v-icon class="mr-1">mdi-plus</v-icon> Create New Category
        </v-btn>
      </v-col>
    </v-row>

    <!-- <DialogComponent @collegeAdded="handleCollegeAdded"></DialogComponent> -->

    <v-data-table
      :headers="headers"
      :items="Get_Category.data"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :server-items-length="total"
      class="elevation-1 mt-5"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="handlePaginationChange"
      :footer-props="{
        'items-per-page-options': itemsPerPageOptions,
      }"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.full_name }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td>
            <span v-if="item.deleted_at === null"
              ><v-chip color="success">Active</v-chip></span
            >
            <span v-else><v-chip color="error">Inactive</v-chip></span>
          </td>
          <td>
            <v-icon v-if="item.deleted_at == null" @click="editCategory(item, 'Update')" color="orange"
              >mdi-pencil</v-icon
            >
            <!-- <v-icon @click="deleteCategory(item)">mdi-delete</v-icon> -->
            <v-icon
              v-if="item.deleted_at !== null"
              @click="restoreCategory(item)"
              color="success"
              >mdi-restore</v-icon
            >
            <v-icon v-else @click="deleteCategory(item)" color="error"
              >mdi-delete</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
//import DialogComponent from '@/views/dialog/Colleges_dialog.vue'; // Import your dialog component
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  components: {
    // DialogComponent,
  },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: [
        { text: "Last Modified By", value: "full_name" },
        { text: "Category", value: "name" },
        { text: "Code", value: "code" },
        { text: "Status", sortable: false },
        { text: "Actions", align: "", value: "actions", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters([
      "Get_Category",
      "Get_Single_Category",
      "Get_role",
      "Get_Colleges",
      "Get_dept_options",
      "Get_add",
    ]),
  },
  watch: {
    Get_add: {
      handler(val) {
        if (val === true) {
          this.Category_fetchTable();
          this.$store.commit("Set_add", false);
        }
      },
    },
  },

  methods: {
    async addCategory() {
      this.$store.commit("SET_DIALOG_STATE_MODE", "Add");
      this.$store.commit("SET_DIALOG_STATUS", true);
      // Commit mutations after both actions are completed
    },

    async editCategory(item, action) {
      this.$store.commit("Set_Single_Category", item);
      this.$store.commit("SET_DIALOG_STATE_MODE", action);
      this.$store.commit("SET_DIALOG_STATUS", true);
    },

    deleteCategory(item) {
      Swal.fire({
        text: "Are you sure to Inactive this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Delete_Category", item.id).then((response) => {
            Swal.fire("Success", "Removed Successfully!", "success");
            this.Category_fetchTable();
            this.$store.dispatch("Category");
          });
        }
      });
    },

    restoreCategory(item) {
      Swal.fire({
        text: "Are you sure to Activate this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, restore it",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Restore_Category", item.id).then((response) => {
            Swal.fire("Success", "Restore Successfully!", "success");
            this.Category_fetchTable();
            this.$store.dispatch("Category");
          });
        }
      });
    },

    Category_searchInput() {
      this.Category_fetchTable();
    },
    async Category_fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      await this.$store.dispatch("Category", payload).then(() => {
        this.total = this.Get_Category.total;
        this.loading = false;
      });
    },
    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.Category_fetchTable();
    },
  },

  mounted() {
    this.$store.dispatch("Colleges").then(() => {
      //console.log(this.Get_Colleges)
    });
    this.$store.dispatch("Role").then(() => {
      //console.log(this.Get_role)

      if (this.Get_role) {
        //console.log(this.Get_role.college_id)
        this.$store
          .dispatch("FetchDepartmentOptions", this.Get_role.college_id)
          .then(() => {
            console.log(this.Get_dept_options);
          });
      }
    });
  },
};
</script>

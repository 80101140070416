<template>
  <v-dialog v-model="Get_Tag_Dialog_Status" persistent max-width="30rem">
    <v-card>
      <v-card-title>{{ Get_Tag_Dialog_State_Mode }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row v-if="Get_Tag_Dialog_State_Mode === 'Add'">
            <v-col cols="12">
              <!-- colleges -->
              <v-autocomplete
                v-model="payload.college_id"
                :items="filteredColleges"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Colleges"
                :rules="rules.required"
                @input="collegeSelectedTag"
                v-if="isAdmin"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- department -->
              <v-autocomplete
                v-model="payload.department_id"
                :items="Get_dept_options.departments"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Departments"
                :rules="rules.required"
                @input="departmentSelected"
                v-if="isDean"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Category -->
              <v-autocomplete
                v-model="payload.category_id"
                :items="Get_Category_options.Categories"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Category"
                :rules="rules.required"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Always show -->
              <v-text-field
                label="Tags"
                :rules="rules.required"
                hide-details="auto"
                v-model="payload.tag"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="form_update">
          <v-row v-if="Get_Tag_Dialog_State_Mode == 'Update'">
            <v-col cols="12">
              <!-- colleges -->
              <v-autocomplete
                v-model="payload.editcollege_id"
                :items="filteredColleges"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Colleges"
                :rules="rules.required"
                @input="editCollegeSelectedTag"
                hide-details="auto"
                v-if="isAdmin"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- department -->
              <v-autocomplete
                v-model="payload.editdepartment_id"
                :items="Get_dept_options.departments"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Departments"
                @input="editdepartmentSelected"
                :rules="rules.required"
                hide-details="auto"
                v-if="isDean"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Category -->
              <v-autocomplete
                v-model="payload.editcategory_id"
                :items="Get_Category_options.Categories"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Category"
                :rules="rules.required"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Always show -->
              <v-text-field
                label="Tags"
                :rules="rules.required"
                hide-details="auto"
                outlined
                v-model="payload.edittag"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialogs()" text>
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="Get_Tag_Dialog_State_Mode === 'Add' ? addTag() : updateTag()"
        >
          {{ Get_Tag_Dialog_State_Mode }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "Get_Tag_Dialog_Status",
      "Get_Tag_Dialog_State_Mode",
      "Get_Single_Tag",
      "Get_dept_options",
      "Get_Colleges",
      "USER_DETAILS",
      "Get_Category_options",
    ]),

    isAdmin() {
      return this.USER_DETAILS.user_role_details.some((role) => role.id === 1);
    },
    isDean() {
      return (
        this.isAdmin ||
        this.USER_DETAILS.user_role_details.some((role) => role.id === 2)
      );
    },
    isCoor() {
      return this.USER_DETAILS.user_role_details.some((role) => role.id === 3);
    },

    filteredColleges() {
      if (this.Get_Colleges && this.Get_Colleges.data) {
        return this.Get_Colleges.data.filter((college) => !college.deleted_at);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      payload: {
        college_id: null,
        department_id: null,
        category_id: null,
        tag: null,

        editcollege_id: null,
        editdepartment_id: null,
        editcategory_id: null,
        edittag: null,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      // isLoading: false,
    };
  },
  methods: {
    closeDialogs() {
      this.$store.commit("SET_Tag_DIALOG_STATUS", false);
      this.$store.commit("SET_Tag_DIALOG_STATE_MODE", null);
      if (this.Get_Tag_Dialog_State_Mode === "Update") {
        this.$store.commit("Clear_Department_Options");
      }
    },

    collegeSelectedTag() {
      if (this.payload.college_id) {
        this.$store
          .dispatch("FetchDepartmentOptions", this.payload.college_id)
          .then(() => {
            // Commit Clear_Category_Options after fetching department options
            this.payload.department_id = null;
            this.$store.commit("Clear_Category_Options");
          })
          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Department_Options");
        this.$store.commit("Clear_Category_Options");
      }
    },

    editCollegeSelectedTag() {
      if (this.payload.editcollege_id) {
        this.$store
          .dispatch("FetchDepartmentOptions", this.payload.editcollege_id)
          .then(() => {
            this.payload.editdepartment_id = null;
            this.payload.editcategory_id = null;
          })

          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Category_Options");
        this.$store.commit("Clear_Department_Options");
      }
    },

    ///

    departmentSelected() {
      if (this.payload.department_id) {
        this.$store
          .dispatch("fetchCategoryOptions", this.payload.department_id)
          .then(() => {
            this.payload.category_id = null;
          })

          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Category_Options");
      }
    },

    editdepartmentSelected() {
      if (this.payload.editcollege_id) {
        this.$store
          .dispatch("fetchCategoryOptions", this.payload.editdepartment_id)
          .then(() => {
            this.payload.editcategory_id = null;
          })

          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Category_Options");
      }
    },

    addTag() {
      if (this.$refs.form.validate()) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("Store_Tags", this.payload)
              .then((response) => {
                if (response === "success") {
                  Swal.fire("Success", "Added Successfully!", "success");
                  //this.$store.commit('Set_add', true);

                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }
                this.$store.dispatch("Tags");
                this.$store.commit("Set_is_change", true);
                this.payload = {
                  tag: null,
                  category_id: null,
                  college_id: null,
                  department_id: null,
                }; // Reset payload
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please Fill out required Fields",
        });
      }
    },
    updateTag() {
      if (this.$refs.form_update.validate()) {
        Swal.fire({
          text: "Are you sure to update this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, update it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("Update_Tag", {
                id: this.Get_Single_Tag.id,
                data: this.payload,
              })
              .then((response) => {
                if (response === "success") {
                  Swal.fire("Success", "Updated Successfully!", "success");
                  this.$store.dispatch("Tags");
                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "Validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }

                // Reset payload
                this.payload = {
                  edittag: null,
                  editcategory_id: null,
                  editcollege_id: null,
                  editdepartment_id: null,
                };
              })
              .catch((error) => {
                console.error("Error:", error);
                this.$swal.fire({
                  icon: "error",
                  title: "Validation Error",
                  text: "Please enter a category name before updating.",
                });
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a category name before updating.",
        });
      }
    },
  },

  mounted() {
    if (this.Get_Tag_Dialog_State_Mode === "Update") {
      Promise.all([
        this.$store.dispatch(
          "FetchDepartmentOptions",
          this.Get_Single_Tag.college_id
        ),
        this.$store.dispatch(
          "fetchCategoryOptions",
          this.Get_Single_Tag.department_id
        ),
      ]).then(() => {
        // Update the form payload
        if (this.Get_Single_Tag) {
          this.payload.editcategory_id = this.Get_Single_Tag.Category_id;
          this.payload.edittag = this.Get_Single_Tag.name;
          this.payload.editcollege_id = this.Get_Single_Tag.college_id;
          this.payload.editdepartment_id = this.Get_Single_Tag.department_id;
        }
      });
    } else if (this.Get_Tag_Dialog_State_Mode === "Add") {
      if (this.USER_DETAILS && this.USER_DETAILS.college_id) {
        this.$store.dispatch(
          "FetchDepartmentOptions",
          this.USER_DETAILS.college_id
        );
        //console.log(this.USER_DETAILS.college_id);
      }

      if (this.isAdmin) {
        this.$store.commit("Clear_Department_Options");
        this.$store.commit("Clear_Category_Options");
      } else if (this.isDean) {
        this.$store.commit("Clear_Category_Options");
      } else if (this.isCoor) {
        this.$store.dispatch(
          "fetchCategoryOptions",
          this.USER_DETAILS.college_id
        );
      }
    }
  },
};
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import User from '@/store/modules/User'
import Category from '@/store/modules/Category'
import Tag from '@/store/modules/Tags'
import Departments from './modules/Departments/index'
import Dropdown from './modules/Dropdown/index'
import Colleges from './modules/Colleges/index'
import Research from './modules/Research/index'
import Analysis from './modules/Analysis/index'
import Activitylogs from './modules/Activitylogs/index'
import ForgotPass from './modules/ForgotPass/index'
import ResearchWithProf from './modules/Profwithresearch/index'

Vue.use(Vuex)

export default new Vuex.Store({
  
  strict: false,
  modules: {
    User,
    Category,
    Tag,
    Departments,
    Dropdown,
    Colleges,
    Research,
    Analysis,
    Activitylogs,
    ForgotPass,
    ResearchWithProf
  }
})

import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#e2e5f4"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VContainer,{staticClass:"container"},[_c(VCard,{staticClass:"bg-card"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"rules":_vm.rules.password,"type":_vm.show ? 'text' : 'password',"label":"Old Password","required":"","outlined":"","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"hide-details":"auto"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"rules":_vm.rules.password,"type":_vm.show2 ? 'text' : 'password',"label":"New Password","required":"","outlined":"","append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off',"hide-details":"auto"},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.form.password0),callback:function ($$v) {_vm.$set(_vm.form, "password0", $$v)},expression:"form.password0"}})],1)],1),_c(VRow,{staticClass:"mb-1"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"rules":_vm.rules.password,"type":_vm.show3 ? 'text' : 'password',"label":"Confirm Password","required":"","outlined":"","append-icon":_vm.show3 ? 'mdi-eye' : 'mdi-eye-off',"hide-details":"auto"},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})],1)],1),_c(VBtn,{attrs:{"type":"submit","color":"#002147","dark":""}},[_vm._v("Submit")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
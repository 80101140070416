import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#002147"}},[(_vm.REGISTER_DIALOG)?_c('register_dialog'):_vm._e(),_c(VCard,{staticClass:"login-card text-center"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/adamson-login-logo.png"),"alt":"ADAMSON Logo","width":"100"}})]),_c(VCardText,[_vm._v("Sign in to start your session")]),_c(VForm,{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-4",attrs:{"rules":_vm.rules.required,"outlined":"","dense":"","label":"Username","hide-details":"auto"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{staticClass:"mb-4",attrs:{"type":"password","rules":_vm.rules.required,"outlined":"","dense":"","label":"Password","hide-details":"auto"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('vue-recaptcha',{ref:"recaptcha",staticClass:"mb-2",attrs:{"sitekey":"6LfPhT0pAAAAAJtonaJ-tyoo1O41wSi41Ci2C0Fu"},on:{"verify":_vm.onRecaptchaVerified,"expired":_vm.onRecaptchaExpired}}),_c('div',{staticStyle:{"display":"flex","justify-content":"center","gap":"0.5rem"}},[_c(VBtn,{attrs:{"color":"#002147","dense":"","dark":""},on:{"click":_vm.regiter}},[_vm._v("Register")]),_c(VBtn,{attrs:{"type":"submit","color":"#002147","dense":"","dark":""}},[_vm._v("Sign in")])],1)],1),_c(VBtn,{attrs:{"color":"#002147","text":"","small":"","block":""},on:{"click":function($event){return _vm.openDialogForgot()}}},[_vm._v("Forgot password")]),(_vm.GET_DIALOG_VAL_FORGOT == true)?_c('forgotDialog'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
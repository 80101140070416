import api from '@/api/index'

export default {
  state: {
    Activity: [],
    selectbox_filter:[],
    pdf: []
  
 
  },

  getters: {
    Get_logs: (state) => state.Activity,
    Get_dropdown: (state)=> state.selectbox_filter,
    Get_pdf: (state) => state.pdf,

  },

  mutations: {
    Set_logs: (state, data) => {
      state.Activity = data;
    },

    Set_pdf: (state, data) => {
      state.pdf = data;
    },

    Set_filter: (state, data) => {
      state.selectbox_filter = data;
    },
 
  },

  actions: {
 

    Logs({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/activity-logs',payload).then((response) => {
            //console.log(response)
          commit('Set_logs', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },


    Dropdown({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/dropdownFilter').then((response) => {
          //console.log(response)
          commit('Set_filter', response.data.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },


    
    generatePdf({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/generatePdf',payload).then((response) => {
            //console.log(response)
          commit('Set_pdf', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

   
  },
};

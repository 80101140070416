<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Profile</p>
        <userProfile v-if="PROFILE_UPDATE == null" />
        <profileUpdate v-else />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import userProfile from "@/views/components/userProfile.vue";
import profileUpdate from "@/views/components/profileUpdate.vue";

export default {
  components: { userProfile, profileUpdate },
  computed: {
    ...mapGetters(["PROFILE_UPDATE"]),
  },
  mounted() {
    this.$store.commit("PROFILE_UPDATE", null);
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

import api from '@/api/index'
export default {
  state: {
    dialog_forgot:
    {
      value: false,
      mode: null
    }
  },
  // global variables for vuex of department
  getters: {
    GET_DIALOG_VAL_FORGOT: (state) => state.dialog_forgot.value,
    GET_DIALOG_MODE_FORGOT: (state) => state.dialog_forgot.mode,
  },

  mutations: {
    SET_DIALOG_VAL_FORGOT: (state, data) => { state.dialog_forgot.value = data },
    SET_DIALOG_MODE_FORGOT: (state, data) => { state.dialog_forgot.mode = data },
   
  },

  actions: {
    sendCode({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/sendResetCode', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    checkCode({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/checkVerificationCode', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    changePass({ commit }, payload) {
      // console.log(payload)
      return new Promise((resolve, reject) => {
        api.post('api/changePassword', payload).then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    

  }
}
<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Admin</p>
        <userOperation :sidenavViewer="'ADMIN'" v-if="USER_OPERATION != null" />
        <user_table :sidenavViewer="'ADMIN'" v-if="USER_OPERATION === null" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import user_table from "@/views/table/user_table.vue";
import userOperation from "@/views/components/userOperation.vue";
export default {
  components: { user_table, userOperation },
  computed: {
    ...mapGetters(["USER_OPERATION"]),
  },
  mounted() {
    this.$store.commit("USER_OPERATION", null);
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

import { VApp } from 'vuetify/lib/components/VApp';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#002754"}},[_c(VList,{staticClass:"orange-bg",staticStyle:{"background":"#ff912e"},attrs:{"dense":"","mini-variant":_vm.isMobile},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/scierims.png"),"alt":"ADAMSON Logo","width":"200"}})]),_c(VList,{attrs:{"dark":""}},[_c(VSubheader,[_vm._v("MAIN NAVIGATION")]),_vm._l((_vm.SIDE_NAV),function(side_nav){return _c(VList,{key:side_nav.id,class:{ 'active-item': _vm.isParentActive(side_nav.name) },attrs:{"dark":""}},[(
          !side_nav.side_nav_children ||
          side_nav.side_nav_children.length === 0
        )?_c(VListItem,{attrs:{"to":{ name: side_nav.name, params: { id: side_nav.id } }}},[_c(VIcon,{staticClass:"icon"},[_vm._v(_vm._s(side_nav.mdi_icon))]),_vm._v(" "+_vm._s(side_nav.name)+" ")],1):_c(VListGroup,{attrs:{"value":_vm.isParentActive(side_nav.name),"color":"white","active-class":"my-active-class"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,{staticClass:"researches"},[_c(VIcon,{staticClass:"icon"},[_vm._v(_vm._s(side_nav.mdi_icon))]),_vm._v(" "+_vm._s(side_nav.name)+" ")],1)]},proxy:true}],null,true)},_vm._l((side_nav.side_nav_children),function(child){return _c(VListItem,{key:child.id,staticClass:"childSideNav",attrs:{"to":{ name: child.name, params: { id: child.id } }}},[_vm._v(" "+_vm._s(child.name)+" ")])}),1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app style="background: #002754">
    <v-list
      style="background: #ff912e"
      dense
      class="orange-bg"
      v-model="drawer"
      :mini-variant="isMobile"
    >
      <img
        src="../../assets/scierims.png"
        alt="ADAMSON Logo"
        width="200"
        class="logo"
      />
    </v-list>

    <v-list dark>
      <v-subheader>MAIN NAVIGATION</v-subheader>

      <v-list
        dark
        v-for="side_nav in SIDE_NAV"
        :key="side_nav.id"
        :class="{ 'active-item': isParentActive(side_nav.name) }"
      >
        <v-list-item
          v-if="
            !side_nav.side_nav_children ||
            side_nav.side_nav_children.length === 0
          "
          :to="{ name: side_nav.name, params: { id: side_nav.id } }"
        >
          <v-icon class="icon">{{ side_nav.mdi_icon }}</v-icon>
          {{ side_nav.name }}
        </v-list-item>

        <v-list-group
          v-else
          :value="isParentActive(side_nav.name)"
          color="white"
          active-class="my-active-class"
        >
          <template v-slot:activator>
            <v-list-item-title class="researches">
              <v-icon class="icon">{{ side_nav.mdi_icon }}</v-icon>
              {{ side_nav.name }}
            </v-list-item-title>
          </template>
          <v-list-item
            v-for="child in side_nav.side_nav_children"
            :key="child.id"
            class="childSideNav"
            :to="{ name: child.name, params: { id: child.id } }"
          >
            <!-- <v-icon>{{ child.mdi_icon }}</v-icon> -->
            {{ child.name }}
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-list>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      closeOnClick: false,
      selectedItem: 1,
      drawer: true,
    };
  },

  methods: {
    isParentActive(routeName) {
      return this.$route.matched.some((route) => route.name === routeName);
    },
  },
  computed: {
    ...mapGetters(["SIDE_NAV", "USER_DETAILS"]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown; // Hide the sidebar on small screens
    },
  },

  mounted() {
    this.$store.dispatch("GetUserDetails").then(() => {
      if (this.USER_DETAILS.status === "Inactive") {
        this.$store.dispatch("Logout").then((response) => {
          if (response["message"] === "success") {
            localStorage.removeItem("rms-token");
            this.$store.commit("SIDE_NAV", null);
            this.$store.commit("USER_DETAILS", {});
            this.$router.push("/Login");
          }
        });
      } else {
        this.$store.commit(
          "PROFILE_PATH",
          this.USER_DETAILS.base64img == null
            ? require("../../assets/sample.jpg")
            : this.USER_DETAILS.base64img
        );
        this.$store.dispatch("GetAccessSideNavs").then((response) => {
          //console.log(response);
        });
      }
    });
  },
};
</script>

<style scoped>
.logo {
  padding: 0.4rem 0.5rem 0.3rem;
  margin: 0;
  width: 16rem;
}

.orange-bg {
  padding: 0;
  margin: 0;
}

.researches {
  margin-left: -0.1rem;
}

.childSideNav {
  background: #002147;
}

.icon {
  margin-right: 2rem;
}
</style>

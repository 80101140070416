<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field v-model="search" label="Search" single-line dense hide-details="auto"
          @keydown.enter="research_searchInput()" solo>
        </v-text-field>
        <!-- <v-icon @click="research_searchInput()">mdi-magnify</v-icon> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="9" class="d-flex align-center justify-end">
        <v-btn color="#002147" @click="add_research_page()" dark class="mr-1">
          <v-icon class="mr-1">mdi-plus</v-icon> Create New Research</v-btn>
        <v-btn color="#002147" @click="generateExcel()" dark>
          <v-icon class="mr-1">mdi-file</v-icon>Export</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="GET_RESEARCH_TABLE_VAL.data" :options.sync="options"
      :items-per-page="itemsPerPage" :server-items-length="total" class="elevation-1 mt-5"
      @update:options="handlePaginationChange" :footer-props="{
          'items-per-page-options': itemsPerPageOptions,
        }">
      <template v-slot:item="{ item }">
        <tr>
          <!-- <td>{{ item.id }}</td> -->
          <td>{{ item.research_title }}</td>
          <!-- <td>{{ item.research_abstract }}</td> -->
          <td>{{ item.research_publication_year }}</td>
          <td>{{ formattedDate(item.research_presentation_date) }}</td>
          <td>{{ item.research_presentation_location }}</td>
          <!-- <td>{{ item.research_details }}</td> -->
          <td>{{ formattedDate(item.research_submission_date) }}</td>
          <div v-for="category in item.relatedCategories">
            {{ category }}
          </div>
          <td>{{ item.full_name }}</td>
          <td>{{ item.research_dept_name }}</td>
          <div v-for="tags in item.related_tags">
            {{ tags }}
          </div>
          <td v-if="item.deleted_at == null">
            <v-chip color="success">Active</v-chip>
          </td>
          <td v-else><v-chip color="error">Inactive</v-chip></td>
          <td>
            <v-icon @click="viewResearch(item)">mdi-eye</v-icon>
            <v-icon color="orange" @click="editResearch(item)" v-if="item.user_id == USER_DETAILS.id || USER_DETAILS.user_role_details.some(role => role.name === 'Admin')">mdi-pencil</v-icon>
            <v-icon color="success" @click="export_item(item)">mdi-upload</v-icon>
            <v-icon color="error" @click="delResearch(item.id)" v-if="item.deleted_at == null && (item.user_id == USER_DETAILS.id || USER_DETAILS.user_role_details.some(role => role.name === 'Admin'))">mdi-delete</v-icon>
            <v-icon color="success" @click="restoreResearch(item.id)" v-if="item.deleted_at != null && (item.user_id == USER_DETAILS.id || USER_DETAILS.user_role_details.some(role => role.name === 'Admin'))">mdi-restore
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import store from "@/store";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import moment from "moment";
// import PDFViewer from 'pdf-viewer';

export default {
  data() {
    return {
      //handle tables
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      headers: [
        // { text: "ID", value: "id" },
        { text: "Title", value: "research_title" },
        // { text: "Abstract", value: "research_abstract" },
        { text: "Publication Date", value: "research_publication_year" },
        { text: "Presentation Date", value: "research_presentation_date" },
        {
          text: "Presentation Location",
          value: "research_presentation_location",
        },
        // { text: "Intellectual Property Detail", value: "research_details" },
        { text: "Submission Date", value: "research_submission_date" },
        { text: "Category", value: "relatedCategories", sortable: false },
        { text: "Created By", value: "full_name" },
        { text: "Departments", value: "research_dept_name" },
        { text: "Tags", value: "status", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async generateExcel() {
      // Create a workbook and add a worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      // Add static data to the worksheet
      worksheet.columns = [
        { header: "Title", key: "research_title", width: 20 },
        { header: "Abstract", key: "research_abstract", width: 20 },
        {
          header: "PublicationYear",
          key: "research_publication_year",
          width: 20,
        },
        {
          header: "Presentation Date",
          key: "research_presentation_date",
          width: 20,
        },
        {
          header: "Presentation Location",
          key: "research_presentation_location",
          width: 20,
        },
        {
          header: "Intellectual Property Detail",
          key: "research_details",
          width: 25,
        },
        {
          header: "Submission Date",
          key: "research_submission_date",
          width: 20,
        },
        { header: "Category", key: "research_dept_name", width: 20 },
        { header: "Tags", key: "related_tags", width: 20 },
      ];

      const payload = {
        params: {
          perPage: -1,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };
      await this.$store
        .dispatch("getAllResearchNoPaginate", payload)
        .then((response) => {
          const modifiedData = response.data;
          worksheet.addRows(modifiedData);

          const currentDate = new Date().toISOString().split("T")[0];
          // Generate the Excel file in memory
          workbook.xlsx.writeBuffer().then((buffer) => {
            // Convert the buffer to a Blob and save it using FileSaver
            const blob = new Blob([buffer], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, `research_export_${currentDate}.xlsx`);
          });
        });
    },
    handlePaginationChange() {
      // console.log(this.options)
    },
    add_research_page() {
      this.$store.commit("set_dialog_val_list", true); // mutation name
      this.$store.commit("set_dialog_mode_list", "ADD"); // mutation name
    },
    editResearch(item) {
      this.$store.commit("set_dialog_val_list", true); // mutation name
      this.$store.commit("set_dialog_mode_list", "EDIT"); // mutation name
      this.$store.commit("SET_EDIT_LIST_VAL", item);
    },
    delResearch(id) {
      // console.log(id)
      Swal.fire({
        text: "Are you sure to delete this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("deleteResearch", { id: id })
            .then((response) => {
              // console.log(response)
              Swal.fire(response.title, response.message, response.icon);
              // this.$store.dispatch("getAllResearchVals")
              this.research_fetchTable();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    },
    restoreResearch(id) {
      Swal.fire({
        text: "Are you sure to restore this?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#008000",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("restoreResearch", { id: id })
            .then((response) => {
              // console.log(response)
              Swal.fire(response.title, response.message, response.icon);
              // this.$store.dispatch("getAllDeptVals")
              this.research_fetchTable();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    },
    research_searchInput() {
      this.research_fetchTable();
    },

    async research_fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      await this.$store.dispatch("getAllResearchVals", payload).then(() => {
        this.total = this.GET_RESEARCH_TABLE_VAL.total;
        // this.loading = false
      });
    },

    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.research_fetchTable(); // Corrected method name
    },
    async export_item(item) {
      try {
        // Dispatch the exportPdf action and wait for the response
        const pdfUrl = await this.$store.dispatch("exportPdf", item.file_path);
        // for file name
        const fileNameA = item.file_path.split("/").pop();
        // Create a link element
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank"; // Open the link in a new tab

        // Extract the file name from the URL or use a default name
        const fileName = item.file_name || "document.pdf";
        link.download = fileNameA;

        // Append the link to the document and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error exporting PDF:", error);
        // Handle the error as needed
      }
    },
    viewResearch(item) {
      this.$store.commit("set_dialog_val_list", true); // mutation name
      this.$store.commit("set_dialog_mode_list", "VIEW"); // mutation name
      this.$store.commit("SET_VIEW_LIST_VAL", item);
    },
    formattedDate(data) {
      return moment(data).format("MMMM D, YYYY");
    }
  },
  computed: {
    ...mapGetters([
      "GET_DIALOG_VAL_LIST",
      "GET_DIALOG_MODE_LIST",
      "GET_RESEARCH_TABLE_VAL",
      "GET_CATEGORY_LIST_VAL",
      "USER_DETAILS"
    ]),
  },
  mounted() {
    // this.$store.dispatch("getAllResearchVals").then(() => {
    //     // console.log(this.GET_RESEARCH_TABLE_VAL)
    //     // console.log(this.GET_RESEARCH_TABLE_VAL)
    // })
      console.log(this.USER_DETAILS);
    this.$store.dispatch("getAllCategoryVals").then(() => {
      // console.log(this.GET_COLLEGE_TABLE_VAL)
    });
    // this.$store.dispatch("getAllDeptVals").then(() => {
    //     // console.log(this.GET_COLLEGE_TABLE_VAL)
    // })
  },
};
</script>

<template>
  <v-dialog v-model="Get_Colleges_Dialog_Status" persistent max-width="30rem">
    <v-card>
      <v-card-title>{{ Get_Colleges_Dialog_State_Mode }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row v-if="Get_Colleges_Dialog_State_Mode == 'Add'">
            <v-col cols="12">
              <v-text-field
                label="Colleges"
                :rules="rules"
                hide-details="auto"
                v-model="payload.name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Abbreviation"
                :rules="rules"
                hide-details="auto"
                v-model="payload.abbvreviation"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="form_update">
          <v-row v-if="Get_Colleges_Dialog_State_Mode == 'Update'">
            <v-col cols="12">
              <v-text-field
                label="Edit Colleges"
                :rules="rules"
                hide-details="auto"
                v-model="payload.editColleges"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Edit Abbreviation"
                :rules="rules"
                hide-details="auto"
                v-model="payload.editAbbreviation"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialogs()" text> Close </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            Get_Colleges_Dialog_State_Mode == 'Add'
              ? addColleges()
              : updateCollege()
          "
        >
          {{ Get_Colleges_Dialog_State_Mode }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "Get_Colleges_Dialog_Status",
      "Get_Colleges_Dialog_State_Mode",
      "Get_S_College",
    ]),
  },
  data() {
    return {
      payload: {
        name: null,
        abbvreviation: null,
        editColleges: null,
        editAbbreviation: null,
      },
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    closeDialogs() {
      this.$store.commit("SET_Colleges_DIALOG_STATUS", false);
      this.$store.commit("SET_Colleges_DIALOG_STATE_MODE", null);
    },

    addColleges() {
      if (this.$refs.form.validate()) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("Store_College", this.payload)
              .then((response) => {
                if (response === "success") {
                  Swal.fire("Success", "Added Successfully!", "success");
                  this.$store.commit("IS_NEW_VAL", true);
                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }
                this.$store.dispatch("Colleges");
                this.payload = { name: null, abbvreviation: null }; // Reset payload
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a category name before adding.",
        });
      }
    },

    updateCollege() {
      if (this.$refs.form_update.validate()) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(this.payload.editdepartment)
            this.$store.dispatch('Update_College', { id: this.Get_S_College.id, data: this.payload })
              .then(response => {
                // console.log(response)
                if (response === "success") {
                  Swal.fire("Success", "Updated Successfully!", "success");

                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }
                this.$store.dispatch("Colleges");
                this.payload.editColleges = null; // Reset editColleges
                this.payload.editAbbreviation = null; // Reset editAbbreviation
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a category name before adding.",
        });
      }
    },
  },
  mounted() {
    // console.log(this.Get_S_College)

    if (this.Get_Colleges_Dialog_State_Mode === "Update") {
      this.payload.editColleges = this.Get_S_College.name;
      this.payload.editAbbreviation = this.Get_S_College.abbvreviation;
   
    }
  },
};
</script>

<template>
  <v-card outlined class="user-profile">
    <p class="font-weight-bold">User Information</p>
    <v-row>
      <v-col cols="12" sm="3" class="text-center">
        <div>
          <img :src="imageUrl" alt="Profile Picture" width="300" />
        </div>
        <v-btn @click="editItem" text block color="primary">Edit Profile</v-btn>
      </v-col>
      <v-col cols="9">
        <h3>Name</h3>
        <p>{{ USER_DETAILS.full_name }}</p>

        <h3>Email</h3>
        <p>{{ USER_DETAILS.email }}</p>

        <h3>Gender</h3>
        <p>{{ USER_DETAILS.gender }}</p>

        <h3>Username</h3>
        <p>{{ USER_DETAILS.username }}</p>

        <h3>Address</h3>
        <p>{{ USER_DETAILS.address }}</p>

        <h3>Role</h3>
        <v-chip v-for="role in USER_DETAILS.user_role_details" :key="role.id">
          {{ role.name }}
        </v-chip>

        <span v-if="USER_DETAILS.college != null">
          <h3>College</h3>
          <p>{{ USER_DETAILS.college }}</p>
        </span>

        <span v-if="USER_DETAILS.department != null">
          <h3>Department</h3>
          <p>{{ USER_DETAILS.department }}</p>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    sidenavViewer: String,
  },

  computed: {
    ...mapGetters(["USER_DETAILS"]),
  },

  data() {
    return {
      imageUrl: require("../../assets/sample.jpg"),
    };
  },

  methods: {
    editItem() {
      this.$store.commit("PROFILE_UPDATE", true);
    },
  },

  // assigning of form value
  mounted() {
    this.imageUrl =
      this.USER_DETAILS.base64img == null
        ? require("../../assets/sample.jpg")
        : this.USER_DETAILS.base64img;
    console.log(this.USER_DETAILS);
  },
};
</script>

<style scoped>
.user-profile {
  padding: 1rem;
}
</style>

<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Activity Logs</p>
        <activitylogs_table />
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import activitylogs_table from "@/views/table/activitylogs_table.vue";

import { mapGetters } from "vuex";
export default {
  components: { activitylogs_table },
  computed: {
    ...mapGetters([]),
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"user-profile",attrs:{"outlined":""}},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("User Information")]),_c(VRow,[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('div',[_c('img',{attrs:{"src":_vm.imageUrl,"alt":"Profile Picture","width":"300"}})]),_c(VBtn,{attrs:{"text":"","block":"","color":"primary"},on:{"click":_vm.editItem}},[_vm._v("Edit Profile")])],1),_c(VCol,{attrs:{"cols":"9"}},[_c('h3',[_vm._v("Name")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.full_name))]),_c('h3',[_vm._v("Email")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.email))]),_c('h3',[_vm._v("Gender")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.gender))]),_c('h3',[_vm._v("Username")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.username))]),_c('h3',[_vm._v("Address")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.address))]),_c('h3',[_vm._v("Role")]),_vm._l((_vm.USER_DETAILS.user_role_details),function(role){return _c(VChip,{key:role.id},[_vm._v(" "+_vm._s(role.name)+" ")])}),(_vm.USER_DETAILS.college != null)?_c('span',[_c('h3',[_vm._v("College")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.college))])]):_vm._e(),(_vm.USER_DETAILS.department != null)?_c('span',[_c('h3',[_vm._v("Department")]),_c('p',[_vm._v(_vm._s(_vm.USER_DETAILS.department))])]):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
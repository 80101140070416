<template>
  <v-dialog v-model="GET_DIALOG_VAL" persistent max-width="30rem">
    <v-card>
      <v-card-title>{{ GET_DIALOG_MODE }}</v-card-title>
      <v-card-text>
        <v-row v-if="GET_DIALOG_MODE == 'ADD'">
          <v-col cols="12">
            <v-autocomplete
              auto-select-first
              chips
              :items="college_items"
              item-text="name"
              item-value="id"
              label="College"
              v-model="payload.collegeId"
              :readonly="this.userRoleId === 2"
              :clearable="this.userRoleId !== 2"
              @change="handleDepartmentChange"
              outlined
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Department Name"
              :rules="rules"
              hide-details="auto"
              v-model="payload.deptName"
              outlined
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12">
                <v-autocomplete auto-select-first chips clearable :items="items" item-text="full_name" item-value="id"
                  label=" Department Chairperson" v-model="payload.chairId"></v-autocomplete>
              </v-col> -->
        </v-row>

        <v-row v-if="GET_DIALOG_MODE == 'EDIT'">
          <v-col cols="12">
            <v-autocomplete
              auto-select-first
              chips
              clearable
              :items="college_items"
              item-text="name"
              item-value="id"
              label="College"
              v-model="payload.edtCollegeId"
              :readonly="this.userRoleId === 2"
              :clearable="this.userRoleId !== 2"
              @change="handleDepartmentChangeEdit"
              outlined
              hide-details="auto"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Edit Department Name"
              :rules="rules"
              hide-details="auto"
              v-model="payload.edtDeptName"
              outlined
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12">
                <v-autocomplete auto-select-first chips clearable deletable-chips label="Department Chairperson"
                  :items="edt_items" item-text="full_name" item-value="id" v-model="payload.edtChairId"></v-autocomplete>
              </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialog()" text> Close </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            GET_DIALOG_MODE == 'ADD' ? addDepartment() : updateDepartment()
          "
        >
          {{ GET_DIALOG_MODE }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { relativeTimeThreshold } from "moment";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "GET_DIALOG_VAL",
      "GET_DIALOG_MODE",
      "GET_CHAIR_VAL",
      "GET_EDT_CHAIR_VAL",
      "GET_EDT_DEPT_VAL",
      "GET_DEL_DEPT_VAL",
      "GET_COLLEGE_VAL",
      "USER_DETAILS",
    ]),
  },
  data() {
    return {
      items: [],
      // edt_items: [],
      college_items: [],
      payload: {
        deptName: null,
        // chairId: null,
        collegeId: null,
        edtDeptName: null,
        // edtChairId: null,
        edtCollegeId: null,
      },
      userRoleId: null,
      rules: [
        (value) => !!value || "Required.",
        // value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$store.commit("set_dialog_val", false);
      this.$store.commit("set_dialog_mode", null);
    },
    addDepartment() {
      // console.log(this.payload)
      if (
        this.payload.deptName === null ||
        this.payload.chairId === null ||
        this.payload.collegeId === null
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a Department name, Chairperson, and College before editing.",
        });
      } else {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("addDepartment", this.payload)
              .then((response) => {
                // console.log(response)
                Swal.fire(response.title, response.message, response.icon);
                this.$store.commit("SET_IS_NEW_VAL_DEPT", true);
                this.$store.dispatch("getAllDeptVals");
                this.closeDialog();
                this.payload = null;
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      }
    },
    updateDepartment() {
      // console.log(this.payload.edtDeptName)
      // console.log(this.payload.edtChairId)
      // console.log(this.payload.edtCollegeId)
      if (
        this.payload.edtDeptName === null ||
        this.payload.edtCollegeId === null
      ) {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a Department name and Chairperson before updating.",
        });
      } else {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("updateDepartment", {
                id: this.GET_EDT_DEPT_VAL.id,
                data: this.payload,
              })
              .then((response) => {
                // console.log(response)
                Swal.fire(response.title, response.message, response.icon);
                this.$store.commit("IS_NEW_VAL_DEPT", true);
                this.$store.dispatch("getAllDeptVals");
                this.closeDialog();
                this.payload = null;
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      }
    },

    handleDepartmentChange() {
      this.$store
        .dispatch("getAllChairVals", this.payload.collegeId)
        .then((response) => {
          // console.log(response)
          // console.log(response.chairpersons)
          this.items = response;
          // this.payload = null
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },

    handleDepartmentChangeEdit() {
      this.$store
        .dispatch("getAllChairValsEdit", this.payload.edtCollegeId)
        .then((response) => {
          // Check if the response and chairpersons property are defined
          this.edt_items = response;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      this.payload.edtChairId = null;
    },
  },
  mounted() {
    // console.log(this.GET_EDT_DEPT_VAL)
    this.college_items = this.GET_COLLEGE_VAL;
    this.payload.edtDeptName = this.GET_EDT_DEPT_VAL.name;
    this.payload.edtCollegeId = this.GET_EDT_DEPT_VAL.college_id;
    // console.log( this.payload.edtChairId)
    // this.payload.edtChairId = this.GET_EDT_DEPT_VAL.department_chairperson_user_id
    // console.log( this.payload.edtChairId)
    this.$store
      .dispatch("getAllChairValsEdit", this.payload.edtCollegeId)
      .then((response) => {
        // Check if the response and chairpersons property are defined
        // console.log(response)
        this.edt_items = response;
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    let firstIdWith2 = null; // Initialize the variable to null

    this.USER_DETAILS.user_role_details.forEach((object) => {
      const id = object.id;
      if (id === 2) {
        firstIdWith2 = id;
        return; // Exit the loop once you find a matching "id"
      }
    });

    // Now, `firstIdWith2` contains the first "id" that is equal to 2.
    this.userRoleId = firstIdWith2;
    if (this.userRoleId === 2) {
      this.payload.collegeId = this.USER_DETAILS.college_id;
      this.payload.edtCollegeId = this.USER_DETAILS.college_id;
    }
  },
};
</script>

<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">Researches by Departments</p>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="college_id"
              :items="COLLEGE_DROPDOWN"
              item-text="name"
              item-value="id"
              :readonly="!isAdmin ? true : false"
              :clearable="isAdmin"
              @change="handleCollegeChange"
              auto-select-first
              chips
              outlined
              label="COLLEGE"
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="department_id"
              :items="DEPARTMENT_DROPDOWN"
              item-text="name"
              :readonly="isAdmin || isDean ? false : true"
              item-value="id"
              :clearable="isAdmin || isDean ? true : false"
              @change="handleDepartmentChange"
              auto-select-first
              chips
              outlined
              label="Department"
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-card outlined>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="currentYear"
                    :items="USED_YEAR"
                    item-text="name"
                    item-value="id"
                    @change="handleYearChange"
                    auto-select-first
                    chips
                    outlined
                    label="YEAR"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <apexchart
                type="line"
                :options="chartOptions"
                :series="chartSeries"
              />
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="col-right">
            <v-card outlined>
              <apexchart
                type="line"
                :options="chartOptions2"
                :series="chartSeries2"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>

        <!-- sakin -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-card outlined>
              <apexchart
                type="bar"
                :options="chartOptionsCategories"
                :series="chartSeriesCategories"
              ></apexchart>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card outlined>
              <apexchart
                type="bar"
                :options="chartOptionsTags"
                :series="chartSeriesTags"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" v-if="isAdmin || isDean || isChairperson">
            <v-card elevation="0" outlined>
              <apexchart
                type="donut"
                :options="chartOptionsPie"
                :series="chartSeriesPie"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "RESEARCHES_COUNT",
      "RESEARCHES_COUNT_BY_YEAR",
      "RESEARCHES_COUNT_BY_ALL_MONTHS",
      "DEPARTMENT_DROPDOWN",
      "USED_YEAR",
      "USER_DETAILS",
      "COLLEGE_DROPDOWN",
      "Get_allCat",
      "Get_allTag",
      "Get_Pie",
    ]),
    //if Logged in user is Admin
    isAdmin() {
      return (
        this.USER_DETAILS &&
        this.USER_DETAILS.user_role_details &&
        this.USER_DETAILS.user_role_details.some((item) => item.id === 1)
      );
    },
    //if Logged in user is dean
    isDean() {
      return (
        this.USER_DETAILS &&
        this.USER_DETAILS.user_role_details &&
        this.USER_DETAILS.user_role_details.some((item) => item.id === 2)
      );
    },
    //if Logged in user is Chairperson
    isChairperson() {
      return (
        this.USER_DETAILS &&
        this.USER_DETAILS.user_role_details &&
        this.USER_DETAILS.user_role_details.some((item) => item.id === 3)
      );
    },
    //if Logged in user is Professor
    isProfessor() {
      return (
        this.USER_DETAILS &&
        this.USER_DETAILS.user_role_details &&
        this.USER_DETAILS.user_role_details.some((item) => item.id === 4)
      );
    },

    chartOptions() {
      return {
        chart: {
          type: "line",
          stacked: false,
        },
        plotOptions: {
          line: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.categoriesVal,
        },
        yaxis: {
          stacked: false,
        },
        title: {
          text: "Monthly Researches",
        },
        legend: {
          show: true,
        },
      };
    },
    chartSeries() {
      return this.chartSeriesVal;
    },

    chartOptions2() {
      return {
        chart: {
          type: "line",
          stacked: false,
        },
        plotOptions: {
          line: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.categoriesVal2,
        },
        yaxis: {
          stacked: false,
        },
        title: {
          text: "Yearly Researches",
        },
        legend: {
          show: true,
        },
      };
    },
    chartSeries2() {
      return this.chartSeriesVal2;
    },

    chartOptionsCategories() {
      return {
        chart: {
          type: "bar",
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.categoryVal,
        },
        yaxis: {
          stacked: true,
        },
        title: {
          text: "Category",
        },
        legend: {
          show: true,
        },
      };
    },

    chartSeriesCategories() {
      return this.chartSeriescat;
    },

    chartOptionsTags() {
      return {
        chart: {
          type: "bar",
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.tagVal,
        },
        yaxis: {
          stacked: true,
        },
        title: {
          text: "Tags",
        },
        legend: {
          show: true,
        },
      };
    },

    chartSeriesTags() {
      return this.chartSeriestag;
    },

    chartOptionsPie() {
      return {
        chart: {
          type: "pie",
        },
        labels: ["Research Contributors", "Non Research Contributors"], // Add your static labels
        title: {
          text: "Pie Chart",
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            customIcons: [],
          },
        },
        colors: ["#36A2EB", "#FF6384"],
      };
    },

    chartSeriesPie() {
      return this.chartData;
    },
  },
  data() {
    return {
      currentYear: null,
      department_id: null,
      department_id2: null,
      college_id: null,
      chartSeriesVal: [],
      categoriesVal: [],
      chartSeriesVal2: [],
      categoriesVal2: [],
      categoryVal: [],
      chartSeriescat: [],
      tagVal: [],
      chartSeriestag: [],
      chartData: [],
    };
  },

  methods: {
    getMonthName(monthNumber) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Check if the provided month number is within a valid range
      if (monthNumber >= 1 && monthNumber <= 12) {
        return months[monthNumber - 1];
      } else {
        return "Invalid Month";
      }
    },

    // COLLEGE DROPDOWN
    async GetCollegeDropdown() {
      await this.$store.dispatch("GetCollegeDropdown").then((response) => {
        // console.log(this.DEPARTMENT_DROPDOWN)
      });
    },
    //DEPARTMENT DROPDOWN
    async GetDepartmentDropdown() {
      let payload = { params: { college_id: null } };
      // if (this.isAdmin) {
      //   await this.$store.dispatch('GetDepartmentDropdown')
      // } else {
      payload.params.college_id = this.college_id;
      await this.$store.dispatch("GetDepartmentDropdownByCollegeId", payload);
      // }
    },
    handleYearChange() {
      this.generateMonthGraph();
    },

    handleCollegeChange() {
      this.department_id = null;
      this.GetDepartmentDropdown();
      this.generateMonthGraph();
      this.generateYearGraph();
      this.generateCategoryCount();
      this.generateTagsCount();
      this.generatePie();
    },

    handleDepartmentChange() {
      this.generateMonthGraph();
      this.generateYearGraph();
      this.generateCategoryCount();
      this.generateTagsCount();
      this.generatePie();
    },

    handleDepartmentChange2() {
      this.generateYearGraph();
    },

    async GetAllUsedYear() {
      await this.$store.dispatch("GetAllUsedYear").then(() => {
        const currentYear = new Date().getFullYear();
        this.currentYear = currentYear;
      });
    },

    generateMonthGraph() {
      const payload = {
        params: {
          isDean: this.isDean,
          isProfessor: this.isProfessor,
          college_id: this.college_id,
          department_id: this.department_id,
          year: this.currentYear,
        },
      };
      this.$store.dispatch("GetResearchCountByAllMonths", payload).then(() => {
        console.log(this.RESEARCHES_COUNT_BY_ALL_MONTHS);
        this.categoriesVal = this.RESEARCHES_COUNT_BY_ALL_MONTHS.allMonths.map(
          (item) => {
            return this.getMonthName(item);
          }
        );
        this.chartSeriesVal =
          this.RESEARCHES_COUNT_BY_ALL_MONTHS.countsByDepartment;
        // this.categoriesVal = this.RESEARCHES_COUNT_BY_ALL_MONTHS.countsByMonth.map(item => { return this.getMonthName(item.month) });
      });
    },

    generateYearGraph() {
      const payload = {
        params: {
          isDean: this.isDean,
          isProfessor: this.isProfessor,
          college_id: this.college_id,
          department_id: this.department_id,
        },
      };
      this.$store.dispatch("GetResearchCountByAllYears", payload).then(() => {
        console.log(this.RESEARCHES_COUNT_BY_YEAR);

        this.chartSeriesVal2 = this.RESEARCHES_COUNT_BY_YEAR.countsByDepartment;
        this.categoriesVal2 = this.RESEARCHES_COUNT_BY_YEAR.countsByYear.map(
          (item) => {
            return item.year;
          }
        );
      });
    },

    generateCategoryCount() {
      const payload = {
        params: {
          isDean: this.isDean,
          // college_id: this.USER_DETAILS.college_id,
          isProfessor: this.isProfessor,
          college_id: this.college_id,
          department_id: this.department_id,
          // department_id: this.department_id2,
        },
      };
      this.$store
        .dispatch("GetCategories", payload)
        .then(() => {
          this.categoryVal = this.Get_allCat.map((item) => item.name);
          this.chartSeriescat = this.Get_allCat;

          //console.log(this.Get_allCat);
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },
    generateTagsCount() {
      const payload = {
        params: {
          isDean: this.isDean,
          // college_id: this.USER_DETAILS.college_id,
          isProfessor: this.isProfessor,
          college_id: this.college_id,
          department_id: this.department_id,
          // department_id: this.department_id2,
        },
      };
      this.$store
        .dispatch("GetTagscount", payload)
        .then(() => {
          this.tagVal = this.Get_allTag.map((item) => item.name);
          this.chartSeriestag = this.Get_allTag;

          // console.log(this.Get_allCat);
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    },

    generatePie() {
      const payload = {
        params: {
          isDean: this.isDean,
          // college_id: this.USER_DETAILS.college_id,
          isProfessor: this.isProfessor,
          college_id: this.college_id,
          department_id: this.department_id,
          // department_id: this.department_id2,
        },
      };
      this.$store
        .dispatch("Piechart", payload)
        .then(() => {
          //console.log('Get_Pie data:', this.Get_Pie);
          this.chartData = this.Get_Pie;
        })
        .catch((error) => {
          console.error("Error fetching Piechart data:", error);
        });
    },
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (this.isDean) {
      this.college_id = this.USER_DETAILS.college_id;
    }
    if (this.isChairperson || this.isProfessor) {
      this.college_id = this.USER_DETAILS.college_id;
      this.department_id = this.USER_DETAILS.department_id;
      this.department_id2 = this.USER_DETAILS.department_id;
    }
    await this.GetAllUsedYear();
    await this.GetCollegeDropdown();
    await this.GetDepartmentDropdown();
    await this.generateYearGraph();
    await this.generateMonthGraph();
    await this.generateCategoryCount();
    await this.generateTagsCount();
    await this.generatePie();
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
.col-right {
  margin-top: 6rem;
}
</style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VTextField,{attrs:{"label":"Search","single-line":"","dense":"","hide-details":"auto","solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.research_searchInput()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c(VDataTable,{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.get_research_prof_table_val.data,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions,
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.handlePaginationChange]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.full_name))]),_c('td',[_vm._v(_vm._s(item.research_title))])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
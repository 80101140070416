<template>
  <v-app style="background: #002147">
    <register_dialog v-if="REGISTER_DIALOG" />
    <v-card class="login-card text-center">
      <div>
        <img
          src="../../assets/adamson-login-logo.png"
          alt="ADAMSON Logo"
          width="100"
        />
      </div>

      <v-card-text>Sign in to start your session</v-card-text>
      <v-form ref="myForm" @submit.prevent="login">
        <v-text-field
          v-model="username"
          :rules="rules.required"
          outlined
          dense
          label="Username"
          hide-details="auto"
          class="mb-4"
        ></v-text-field>
        <v-text-field
          v-model="password"
          type="password"
          :rules="rules.required"
          outlined
          dense
          label="Password"
          hide-details="auto"
          class="mb-4"
        ></v-text-field>

        <vue-recaptcha
          ref="recaptcha"
          class="mb-2"
          sitekey="6LfPhT0pAAAAAJtonaJ-tyoo1O41wSi41Ci2C0Fu"
          @verify="onRecaptchaVerified"
          @expired="onRecaptchaExpired"
        ></vue-recaptcha>

        <!-- <v-checkbox
          v-model="rememberMe"
          label="Remember me"
          class="float-left"
          dense
        ></v-checkbox> -->
        <div style="display: flex; justify-content: center; gap: 0.5rem">
          <v-btn @click="regiter" color="#002147" dense dark>Register</v-btn>
          <v-btn type="submit" color="#002147" dense dark>Sign in</v-btn>
        </div>
      </v-form>

      <v-btn color="#002147" @click="openDialogForgot()" text small block
        >Forgot password</v-btn
      >
      <forgotDialog v-if="GET_DIALOG_VAL_FORGOT == true" />
    </v-card>
  </v-app>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
import forgotDialog from "../dialog/Forgot_Pass_dialog.vue";
import register_dialog from "../dialog/register_dialog.vue";
export default {
  components: { forgotDialog, register_dialog, VueRecaptcha },
  data() {
    return {
      rememberMe: false,
      username: null,
      password: null,
      role: null,
      user_id: null,
      rules: {
        required: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
        ],
        min: [(v) => v.length >= 8 || "Min 8 characters"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        contact: [
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
          (v) =>
            (v !== null && v !== undefined && v.length >= 11) ||
            "Min 11 characters",
          (v) =>
            (v !== null && v !== undefined && v.length <= 11) ||
            "Max 11 characters",
        ],
      },
    };
  },

  computed: {
    ...mapGetters([
      "SIDE_NAV",
      "GET_DIALOG_VAL_FORGOT",
      "GET_DIALOG_MODE_FORGOT",
      "REGISTER_DIALOG",
    ]),
  },

  mounted() {
    const savedUsername = localStorage.getItem("rms-username");
    const savedPassword = localStorage.getItem("rms-password");
    if (savedUsername != null && savedPassword != null) {
      this.username = savedUsername;
      this.password = savedPassword;
    }
  },

  methods: {
    regiter() {
      this.$store.commit("REGISTER_DIALOG", true);
    },
    openDialogForgot() {
      this.$store.commit("SET_DIALOG_VAL_FORGOT", true); // mutation name
      this.$store.commit("SET_DIALOG_MODE_FORGOT", "Forgot Password"); // mutation name
    },
    login() {
      if (this.$refs.recaptcha && this.recaptchaResponse) {
        // Your existing login logic
        if (this.$refs.myForm.validate()) {
          const payload = {
            username: this.username,
            password: this.password,
          };
          this.$store.dispatch("LOGIN", payload).then((response) => {
            if (response.message === "success") {
              // if (this.rememberMe) {
              // localStorage.setItem("rms-token", response.token)
              // localStorage.setItem("rms-username", this.username)
              // localStorage.setItem("rms-password", this.password)
              // } else {
              // localStorage.removeItem("rms-username")
              // localStorage.removeItem("rms-password")
              // document.cookie = `session_rms=${response.token}; path=/`;
              // }
              sessionStorage.setItem("rms-token", response.token);
              this.$store.dispatch("GetAccessSideNavs").then((response) => {
                this.$router.push(response[0].name);
              });
            } else if (response.message === "not active") {
              this.$swal.fire({
                icon: "warning", // Set a warning icon (you can choose a different icon class)
                title: "Account is Not Activated", // Updated title
                text: "Please Wait For Activation Of Your Account", // Updated text message
                showConfirmButton: false, // Remove the "OK" button
                timer: 2000, // Auto-close the alert after 1.5 seconds (adjust as needed)
              });
            } else {
              this.$swal.fire({
                icon: "warning", // Set a warning icon (you can choose a different icon class)
                title: "Credential Incorrect!", // Updated title
                text: "Please Check your Username or Password!", // Updated text message
                showConfirmButton: false, // Remove the "OK" button
                timer: 2000, // Auto-close the alert after 1.5 seconds (adjust as needed)
              });
            }
          });
        }
      } else {
        // Show an error message or take appropriate action if reCAPTCHA is not verified
        console.error("reCAPTCHA not verified");
        this.$swal.fire({
          icon: "warning", // Set a warning icon (you can choose a different icon class)
          title: "reCAPTCHA not verified", // Updated title
          showConfirmButton: false, // Remove the "OK" button
          timer: 2000, // Auto-close the alert after 1.5 seconds (adjust as needed)
        });
      }
    },

    onRecaptchaVerified(response) {
      this.recaptchaResponse = response;
    },

    // Handle reCAPTCHA expiration
    onRecaptchaExpired() {
      this.recaptchaResponse = null;
    },

    registration() {
      this.$router.push("/Registration");
    },
  },
};
</script>

<style scoped>
.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  padding: 2rem 3rem;
}
</style>

<template>
  <v-dialog v-model="register_dialog" :max-width="max_width" persistent>
    <v-card class="create-user" outlined style="padding: 1rem">
      <v-form ref="form" @submit.prevent="register" class="form">
        <p style="align-self: center; font-weight: bold">
          STUDENT REGISTRATION
        </p>

        <div class="responsive_div">
          <v-autocomplete
            v-model="form.college_id"
            :items="COLLEGE_DROPDOWN"
            @change="handleCollegeChange"
            :rules="rules.required"
            item-text="name"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Colleges"
            hide-details="auto"
            height="2"
            class="responsive_item"
          >
          </v-autocomplete>

          <v-autocomplete
            v-model="form.department_id"
            :items="DEPARTMENT_DROPDOWN"
            item-text="name"
            :rules="rules.required"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Department"
            hide-details="auto"
            height="2"
            class="responsive_item"
          >
          </v-autocomplete>
        </div>

        <div class="responsive_div">
          <v-autocomplete
            v-model="form.year"
            :items="[
              '1st Year',
              '2nd Year',
              '3rd Year',
              '4th Year',
              '5th Year',
            ]"
            item-text="name"
            :rules="rules.required"
            item-value="id"
            auto-select-first
            chips
            outlined
            label="Year"
            hide-details="auto"
            height="2"
            class="responsive_item"
          >
          </v-autocomplete>

          <v-text-field
            v-model="form.section"
            :rules="rules.required"
            outlined
            label="Section"
            hide-details="auto"
            class="responsive_item"
          ></v-text-field>
        </div>

        <div class="responsive_div">
          <v-text-field
            v-model="form.first_name"
            :rules="rules.required"
            outlined
            label="First Name"
            hide-details="auto"
          ></v-text-field>

          <v-text-field
            v-model="form.middle_name"
            outlined
            label="Middle Name"
            hide-details="auto"
          ></v-text-field>

          <v-text-field
            v-model="form.last_name"
            :rules="rules.required"
            outlined
            label="Last Name"
            hide-details="auto"
          ></v-text-field>
        </div>
        <v-text-field
          v-model="form.email"
          :rules="rules.email"
          outlined
          label="Email"
          hide-details="auto"
        ></v-text-field>

        <v-autocomplete
          v-model="form.gender"
          :items="genders"
          item-text="name"
          item-value="name"
          :rules="rules.required"
          auto-select-first
          chips
          outlined
          label="Gender"
          hide-details="auto"
        ></v-autocomplete>

        <v-text-field
          v-model="form.address"
          :rules="rules.required"
          outlined
          label="Address"
          hide-details="auto"
        ></v-text-field>

        <v-text-field
          v-model="form.username"
          :rules="rules.required"
          outlined
          label="Username"
          hide-details="auto"
        ></v-text-field>

        <v-text-field
          v-model="form.password"
          :rules="rules.password"
          :type="show ? 'text' : 'password'"
          label="Password"
          required
          outlined
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show = !show"
          hide-details="auto"
        ></v-text-field>

        <div style="display: flex; justify-content: end; gap: 0.5rem">
          <v-btn @click="closeDialog()" color="#820909" dark>Cancel</v-btn>
          <v-btn color="#002147" type="submit" dark>Submit</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
export default {
  created() {
    this.GetCollegeDropdown();
    this.GetDepartmentDropdown();
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    register() {
      if (this.$refs.form.validate()) {
        const payload = this.form;
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You are about to submit the form!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$store.dispatch("StoreUser", payload).then((response) => {
                if (response === "success") {
                  this.$store.commit("REGISTER_DIALOG", false);
                  this.$swal.fire({
                    title: "Success!",
                    text: "Form submitted successfully!",
                    icon: "success",
                  });
                } else {
                  this.$swal.fire({
                    title: "Validation Error",
                    text: response,
                    icon: "warning",
                  });
                }
              });
            }
          });
      }
    },

    onResize() {
      if (window.innerWidth < 600) {
        this.max_width = "100%";
      } else if (window.innerWidth > 600) {
        this.max_width = "80%";
      }
    },

    closeDialog() {
      this.$store.commit("REGISTER_DIALOG", false);
    },

    // COLLEGE DROPDOWN
    async GetCollegeDropdown() {
      await this.$store.dispatch("GetCollegeDropdown").then((response) => {
        // console.log(response);
      });
    },

    //DEPARTMENT DROPDOWN
    async GetDepartmentDropdown() {
      let payload = { params: { college_id: null } };
      payload.params.college_id = this.form.college_id;
      await this.$store.dispatch("GetDepartmentDropdownByCollegeId", payload);
    },

    handleCollegeChange() {
      this.GetDepartmentDropdown();
      this.form.department_id = null;
    },
  },

  computed: {
    ...mapGetters(["COLLEGE_DROPDOWN", "DEPARTMENT_DROPDOWN"]),
  },
  data() {
    return {
      max_width: "100%",
      genders: [{ name: "Male" }, { name: "Female" }, { name: "Others" }],
      show: true,
      register_dialog: true,
      form: {
        year: null,
        section: null,
        user_id: null,
        username: null,
        password: null,
        age: null,
        status: "Active",
        gender: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        address: null,
        department_id: null,
        college_id: null,
        user_role_details_ids: [5],
      },
      rules: {
        password: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && v.length >= 8) ||
            "Min 8 characters",
        ],
        required: [
          (v) => !!v || "Field is required",
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
        ],
        min: [(v) => v.length >= 8 || "Min 8 characters"],
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        contact: [
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
          (v) =>
            (v !== null && v !== undefined && v.length >= 11) ||
            "Min 11 characters",
          (v) =>
            (v !== null && v !== undefined && v.length <= 11) ||
            "Max 11 characters",
        ],
        age: [
          (v) =>
            (v !== null && v !== undefined && !/^\s*$/.test(v)) ||
            "Field is required",
          (v) =>
            (v !== null && v !== undefined && /^\d+$/.test(v)) ||
            "Only numeric values are allowed",
        ],
      },
    };
  },
};
</script>

<style>

/* mobile */
@media (max-width: 640px) {
  .responsive_div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* width: 100%; */
    margin: auto;
  }
}

/* higer width than mobile */
@media (min-width: 640px) {
  .responsive_div {
    display: flex;
    gap: 0.5rem;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* width: 100%; */
    margin: auto;
  }
  .responsive_item {
    width: 50%;
  }
}
</style>
import api from '@/api/index'
export default {
  state: {
    dialog_prof_with_research:
    {
      value: false,
      mode: null
    },
    profWithResearch: [],
  },
  // global variables for vuex of department
  getters: {
    // GET_DIALOG_VAL_LIST: (state) => state.dialog_prof_with_research.value,
    // GET_DIALOG_MODE_LIST: (state) => state.dialog_prof_with_research.mode,
    get_research_prof_table_val: (state) => state.profWithResearch,
  },

  mutations: {
    // set_dialog_val_list: (state, data) => { state.dialog_prof_with_research.value = data },
    // set_dialog_mode_list: (state, data) => { state.dialog_prof_with_research.mode = data },
    set_research_prof_table_val: (state, data) => { state.profWithResearch = data }
  },

  actions: {
    // commit mutations
    getAllResearchWithProfVals({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/showResearchWithProf', payload).then((response) => {
          // pag commit mutations toh
          // console.log(response);
          commit('set_research_prof_table_val', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    }
  }
}
<template>
  <v-app style="background: #e2e5f4">
    <v-container class="container">
      <v-card class="bg-card">
        <p class="font-weight-bold">List</p>
        <Tables v-if="GET_DIALOG_MODE_LIST === null" />
        <addResearch v-if="GET_DIALOG_MODE_LIST === 'ADD'" />
        <editResearch v-if="GET_DIALOG_MODE_LIST === 'EDIT'" />
        <viewResearch v-if="GET_DIALOG_MODE_LIST === 'VIEW'" />
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import Tables from "@/views/tables/ResearchTable.vue";
import addResearch from "@/views/components/ADD_RESEARCH.vue";
import editResearch from "@/views/components/EDIT_RESEARCH.vue";
import viewResearch from "@/views/components/VIEW_RESEARCH.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Tables,
    addResearch,
    editResearch,
    viewResearch,
  },
  computed: {
    ...mapGetters([
      "GET_DIALOG_VAL_LIST",
      "GET_DIALOG_MODE_LIST",
      "GET_DEPT_VAL",
      "GET_DEL_DEPT_VAL",
      "GET_COLLEGE_VAL",
    ]),
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}
.bg-card {
  border-top: 5px solid #ff7900;
  padding: 1rem;
}
</style>

<template>
  <v-card elevation="0">
    <v-row>
      <v-col cols="12" sm="3">
        <v-text-field v-model="search" label="Search" single-line dense hide-details="auto"
          @keydown.enter="searchInputLog()" solo></v-text-field>
        <!-- <v-icon @click="searchInputLog()">mdi-magnify</v-icon> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3">
        <v-select v-model="selectedFilter" :items="DropdownItems" label="Filter by" @change="fetchTable" outlined dense
          hide-details="auto"></v-select>
      </v-col>

      <v-col cols="12" sm="2">
        <v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startDate" label="Start Date" v-bind="attrs" v-on="on" clearable
              @click:clear="clearStartDate" outlined hide-details="auto" dense></v-text-field>
          </template>
          <v-date-picker v-model="startDate" no-title scrollable @input="fetchTable"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" sm="2">
        <v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endDate" label="End Date" v-bind="attrs" v-on="on" clearable
              @click:clear="clearEndDate" outlined hide-details="auto" dense></v-text-field>
          </template>
          <v-date-picker v-model="endDate" no-title scrollable @input="fetchTable"></v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="text-right">
        <v-btn @click="generatePDF" color="primary">Generate PDF</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="Get_logs.data" :options.sync="options" :items-per-page="itemsPerPage"
      :server-items-length="total" class="elevation-1 mt-5" :loading="loading" loading-text="Loading... Please wait"
      @update:options="handlePaginationChange" :footer-props="{ 'items-per-page-options': itemsPerPageOptions }"
      ref="dataTable">
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.description }}</td>
          <td>{{ item.properties ? JSON.parse(item.properties).event : "" }}</td>
          <!-- <td>{{ item.created_at }}</td> -->
          <td>{{ formattedDate(item.created_at) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import html2pdf from "vue-html2pdf";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DialogComponent from "@/views/dialog/Colleges_dialog.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {
    DialogComponent,
  },
  data() {
    return {
      itemsPerPageOptions: [5, 10, 20, 30, 50, -1],
      total: 10,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      options: {},
      search: "",
      sortBy: "",
      sortDesc: false,
      selectedFilter: "all", // initialize selectedFilter
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,

      headers: [
        { text: "Description", value: "description" },
        { text: "Event", value: "properties" },
        { text: "Timestamp", value: "properties" },
      ],
    };
  },
  computed: {
    ...mapGetters(["Get_logs", "Get_dropdown", "Get_pdf"]),
    DropdownItems() {
      // Create a copy of the dynamic options
      if (Array.isArray(this.Get_dropdown.events)) {
        const dynamicOptions = [...this.Get_dropdown.events];
        dynamicOptions.unshift({ text: "All", value: "all" });
        return dynamicOptions;
      } else {
        return [{ text: "All", value: "all" }];
      }
    },
  },
  methods: {
    searchInputLog() {
      this.fetchTable();
    },

    clearStartDate() {
      this.startDate = null;
      this.fetchTable();
    },
    clearEndDate() {
      this.endDate = null;
      this.fetchTable();
    },
    generatePDF() {
      // Fetch all data without pagination for PDF generation
      const payload = {
        params: {
          perPage: -1,
          page: 1,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          selectedFilter: this.selectedFilter,
          startDate: this.startDate ? this.formatDate(this.startDate) : null,
          endDate: this.endDate ? this.formatDate(this.endDate) : null,
        },
      };

      this.$store.dispatch("generatePdf", payload).then(() => {
        const pdf = new jsPDF("p", "pt", "letter");

        // Add title
        pdf.setFontSize(18);
        pdf.text("Activity Logs", 40, 20);

        // Create headers for the table
        const headers = this.headers.map((header) => header.text);

        // Extract data from Get_pdf
        const data = this.Get_pdf.data.map((item) => [
          item.description,
          item.properties ? JSON.parse(item.properties).event : "",
          item.created_at,
        ]);

        // Use jspdf-autotable for automatic table creation
        pdf.autoTable({
          head: [headers],
          body: data,
          startY: 50, // Adjust the starting Y position to leave space for the title
        });

        // Save the PDF
        pdf.save("table.pdf");
      });
    },

    async fetchTable() {
      const payload = {
        params: {
          perPage: this.options.itemsPerPage,
          page: this.options.page,
          search: this.search,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          selectedFilter: this.selectedFilter,
          startDate: this.startDate ? this.formatDate(this.startDate) : null,
          endDate: this.endDate ? this.formatDate(this.endDate) : null,
        },
      };

      await this.$store.dispatch("Logs", payload).then(() => {
        this.total = this.Get_logs.total;
        this.loading = false;
      });
    },
    handlePaginationChange(options) {
      this.sortBy = options.sortBy[0];
      this.sortDesc = options.sortDesc[0];
      this.fetchTable();
    },
    formatDate(date) {
      if (date) {
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split("T")[0]; // Get only the date part
      }
      return null;
    },

    formattedDate(data) {
      return moment(data).format("MMMM D, YYYY - hh:mm A");
    },
  },
  mounted() {
    this.$store.dispatch("Dropdown").then(() => {
      //console.log(this.Get_dropdown.events)
    });
  },
};
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VTextField,{attrs:{"label":"Search","single-line":"","dense":"","hide-details":"auto","solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.research_searchInput()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"9"}},[_c(VBtn,{staticClass:"mr-1",attrs:{"color":"#002147","dark":""},on:{"click":function($event){return _vm.add_research_page()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Create New Research")],1),_c(VBtn,{attrs:{"color":"#002147","dark":""},on:{"click":function($event){return _vm.generateExcel()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-file")]),_vm._v("Export")],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.GET_RESEARCH_TABLE_VAL.data,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.total,"footer-props":{
        'items-per-page-options': _vm.itemsPerPageOptions,
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.handlePaginationChange]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.research_title))]),_c('td',[_vm._v(_vm._s(item.research_publication_year))]),_c('td',[_vm._v(_vm._s(_vm.formattedDate(item.research_presentation_date)))]),_c('td',[_vm._v(_vm._s(item.research_presentation_location))]),_c('td',[_vm._v(_vm._s(_vm.formattedDate(item.research_submission_date)))]),_vm._l((item.relatedCategories),function(category){return _c('div',[_vm._v(" "+_vm._s(category)+" ")])}),_c('td',[_vm._v(_vm._s(item.full_name))]),_c('td',[_vm._v(_vm._s(item.research_dept_name))]),_vm._l((item.related_tags),function(tags){return _c('div',[_vm._v(" "+_vm._s(tags)+" ")])}),(item.deleted_at == null)?_c('td',[_c(VChip,{attrs:{"color":"success"}},[_vm._v("Active")])],1):_c('td',[_c(VChip,{attrs:{"color":"error"}},[_vm._v("Inactive")])],1),_c('td',[_c(VIcon,{on:{"click":function($event){return _vm.viewResearch(item)}}},[_vm._v("mdi-eye")]),(item.user_id == _vm.USER_DETAILS.id || _vm.USER_DETAILS.user_role_details.some(role => role.name === 'Admin'))?_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editResearch(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),_c(VIcon,{attrs:{"color":"success"},on:{"click":function($event){return _vm.export_item(item)}}},[_vm._v("mdi-upload")]),(item.deleted_at == null && (item.user_id == _vm.USER_DETAILS.id || _vm.USER_DETAILS.user_role_details.some(role => role.name === 'Admin')))?_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.delResearch(item.id)}}},[_vm._v("mdi-delete")]):_vm._e(),(item.deleted_at != null && (item.user_id == _vm.USER_DETAILS.id || _vm.USER_DETAILS.user_role_details.some(role => role.name === 'Admin')))?_c(VIcon,{attrs:{"color":"success"},on:{"click":function($event){return _vm.restoreResearch(item.id)}}},[_vm._v("mdi-restore ")]):_vm._e()],1)],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
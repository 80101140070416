<template>
  <v-dialog v-model="Get_Dialog_Status" persistent max-width="30rem">
    <v-card>
      <v-card-title>{{ Get_Dialog_State_Mode }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row v-if="Get_Dialog_State_Mode === 'Add'">
            <v-col cols="12">
              <!-- Show when Get_role is 1 -->
              <v-autocomplete
                v-model="payload.college_id"
                :items="filteredColleges"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Colleges"
                :rules="rules.required"
                @input="collegeSelected"
                v-if="this.Get_role.roles.includes(1)"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Show when Get_role is 1 or 2 -->
              <v-autocomplete
                v-model="payload.department_id"
                :items="Get_dept_options.departments"
                item-text="name"
                item-value="id"
                auto-select-first
                chips
                outlined
                label="Departments"
                :rules="rules.required"
                hide-details="auto"
                v-if="Get_role.roles.includes(1) || Get_role.roles.includes(2)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <!-- Always show -->
              <v-text-field
                label="Category"
                :rules="rules.required"
                hide-details="auto"
                outlined
                v-model="payload.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <!-- Always show -->
              <v-text-field
                label="Code"
                :rules="rules.required"
                hide-details="auto"
                outlined
                v-model="payload.code"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-form ref="form_update">
          <v-row v-if="Get_Dialog_State_Mode == 'Update'">
            <v-col cols="12">
              <v-autocomplete
                v-model="payload.editcolleges"
                :items="filteredColleges"
                item-text="name"
                hide-details="auto"
                item-value="id"
                auto-select-first
                small-chips
                outlined
                label="Colleges"
                :rules="rules.required"
                @change="editCollegeSelected"
                v-if="this.Get_role.roles.includes(1)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="payload.editdepartment"
                :items="Get_dept_options.departments"
                hide-details="auto"
                item-text="name"
                item-value="id"
                auto-select-first
                small-chips
                outlined
                label="Departments"
                :rules="rules.required"
                v-if="Get_role.roles.includes(1) || Get_role.roles.includes(2)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Category"
                :rules="rules.required"
                hide-details="auto"
                outlined
                v-model="payload.editname"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Code"
                :rules="rules.required"
                hide-details="auto"
                outlined
                v-model="payload.editcode"
              ></v-text-field>
            </v-col>
            <!-- </v-row> -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeDialogs()" text> Close </v-btn>
        <v-btn
          color="primary"
          text
          @click="
            Get_Dialog_State_Mode === 'Add' ? addCategory() : updateCategory()
          "
        >
          {{ Get_Dialog_State_Mode }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "Get_Dialog_Status",
      "Get_Dialog_State_Mode",
      "Get_Single_Category",
      "Get_dept_options",
      "Get_Colleges",
      "Get_role",
    ]),
    filteredColleges() {
      if (this.Get_Colleges && this.Get_Colleges.data) {
        return this.Get_Colleges.data.filter((college) => !college.deleted_at);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      payload: {
        college_id: null,
        department_id: null,
        name: null,
        code: null,

        editcolleges: null,
        editdepartment: null,
        editname: null,
        editcode: null,
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      // isLoading: false,
    };
  },
  methods: {
    closeDialogs() {
      this.$store.commit("SET_DIALOG_STATUS", false);
      this.$store.commit("SET_DIALOG_STATE_MODE", null);
      if (this.Get_Dialog_State_Mode === "Update") {
        this.$store.commit("Clear_Department_Options");
      }
    },

    collegeSelected() {
      if (this.payload.college_id) {
        this.$store
          .dispatch("FetchDepartmentOptions", this.payload.college_id)
          .then(() => {
            this.payload.department_id = null;
          })

          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Department_Options");
      }
    },

    editCollegeSelected() {
      if (this.payload.editcolleges) {
        this.$store
          .dispatch("FetchDepartmentOptions", this.payload.editcolleges)
          .then(() => {
            this.payload.editdepartment = null;
          })

          .catch((error) => {
            console.error("Error fetching department options:", error);
          });
      } else {
        this.$store.commit("Clear_Department_Options");
      }
    },

    addCategory() {
      if (this.$refs.form.validate()) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("Store_Category", this.payload)
              .then((response) => {
                if (response === "success") {
                  Swal.fire("Success", "Added Successfully!", "success");
                  this.$store.commit("Set_add", true);
                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }
                this.$store.dispatch("Category");
                this.payload = {
                  name: null,
                  code: null,
                  college_id: null,
                  department_id: null,
                }; // Reset payload
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a category name before adding.",
        });
      }
    },

    updateCategory() {
      if (this.$refs.form_update.validate()) {
        Swal.fire({
          text: "Are you sure to add this?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, add it!",
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(this.payload.editdepartment);
            this.$store
              .dispatch("Update_Category", {
                id: this.Get_Single_Category.id,
                data: this.payload,
              })
              .then((response) => {
                // console.log(response)
                if (response === "success") {
                  Swal.fire("Success", "Updated Successfully!", "success");
                  this.closeDialogs();
                } else {
                  this.$swal.fire({
                    title: "validation Error",
                    text: response,
                    icon: "warning",
                  });
                  this.closeDialogs();
                }
                this.$store.dispatch("Category");
                this.payload = {
                  editname: null,
                  editcode: null,
                  editcolleges: null,
                  editdepartment: null,
                }; // Reset payload
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Please enter a category name before adding.",
        });
      }
    },
  },

  mounted() {
    if (this.Get_Dialog_State_Mode === "Update") {
      this.$store
        .dispatch("FetchDepartmentOptions", this.Get_Single_Category.college_id)
        .then(() => {
          if (this.Get_Single_Category) {
            this.payload.editname = this.Get_Single_Category.name;
            this.payload.editcode = this.Get_Single_Category.code;
            this.payload.editcolleges = this.Get_Single_Category.college_id;
            this.payload.editdepartment =
              this.Get_Single_Category.department_id;
          }
        });
    } else if (this.Get_Dialog_State_Mode === "Add") {
      //  this.$store.dispatch('FetchDepartmentOptions', this.Get_role.college_id)
      //console.log(this.Get_role.college_id)

      if (this.Get_role.roles.includes(1)) {
        this.$store.commit("Clear_Department_Options");
      }
    }
  },
};
</script>

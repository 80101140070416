import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3"}},[_c(VTextField,{attrs:{"label":"Search","single-line":"","hide-details":"auto","solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.Tags_searchInput()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"9"}},[_c(VBtn,{attrs:{"color":"#002147","dark":""},on:{"click":function($event){return _vm.addCategory()}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Create New Tag ")],1)],1)],1),_c(VDataTable,{staticClass:"elevation-1 mt-5",attrs:{"headers":_vm.headers,"items":_vm.Get_Tag.data,"options":_vm.options,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Loading... Please wait","footer-props":{
      'items-per-page-options': _vm.itemsPerPageOptions,
    }},on:{"update:options":[function($event){_vm.options=$event},_vm.handlePaginationChange]},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.full_name))]),_c('td',[_vm._v(_vm._s(item.category_name))]),_c('td',[_c(VChip,[_vm._v(_vm._s(item.name))])],1),_c('td',[(item.deleted_at === null)?_c('span',[_c(VChip,{attrs:{"color":"success"}},[_vm._v("Active")])],1):_c('span',[_c(VChip,{attrs:{"color":"error"}},[_vm._v("Inactive")])],1)]),_c('td',[(item.deleted_at == null)?_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editTag(item, 'Update')}}},[_vm._v("mdi-pencil")]):_vm._e(),(item.deleted_at !== null)?_c(VIcon,{attrs:{"color":"success"},on:{"click":function($event){return _vm.restoreTag(item)}}},[_vm._v("mdi-restore")]):_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteTag(item)}}},[_vm._v("mdi-delete")])],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import api from '@/api/index'

export default {
  state: {
    Tags: [],
    mode: null,
    status: false,
    SingleTag: [],
    loading: false,
    categoryOptions: [], // Store category options here
    searchQuery: "",
    is_change: false,

  },

  getters: {
    Get_Tag: (state) => state.Tags,
    Get_Tag_Dialog_Status: (state) => state.status,
    Get_Tag_Dialog_State_Mode: (state) => state.mode,
    Get_Single_Tag: (state) => state.SingleTag,
    is_Tag_Loading: (state) => state.loading,
    Get_Category_options: (state) => state.categoryOptions,
    Get_Tag_SearchQuery: (state) => state.searchQuery, //live search
    Get_c: (state) => state.is_change,
   
  },

  mutations: {
    Set_Tags: (state, data) => {
      state.Tags = data;
    },
    SET_Tag_DIALOG_STATUS: (state, data) => {
      state.status = data;
    },
    SET_Tag_DIALOG_STATE_MODE: (state, data) => {
      state.mode = data;
    },
    Set_Single_Tag: (state, data) => {
      state.SingleTag = data;
    },

    Set_Tag_Loading(state, loading) {
      state.loading = loading;
    },
    SET_CATEGORY_OPTIONS(state, options) {
        state.categoryOptions = options;
      },
      Clear_Category_Options: (state) => {
        state.categoryOptions = [];
      },
    Set_Tag_SearchQuery(state, data) {
        state.searchQuery = data;
    },
    Set_is_change(state, data) {
      state.is_change  = data;
    },
  
  },

  actions: {


    Tags({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/showTag',payload).then((response) => {
          //console.log(response)
          commit('Set_Tags', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    fetchCategoryOptions({ commit }, department_id) { // sa dropdown to sa dialog ng category dapat to may filtering din
        return new Promise((resolve, reject) => {
          api.get(`api/categoryTodepartment/${department_id}`).then((response) => {
          //console.log(response)
            commit('SET_CATEGORY_OPTIONS', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },

      Store_Tags({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.post(`api/addTag`,payload).then((response) => {
          //  console.log(response)
            
            // commit('Set_Tags', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      Single_Tag({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.get(`api/editTag/${payload}`).then((response) => {
           // console.log(response)
            // commit('Set_Single_Tag', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },

      Delete_Tag({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.delete(`api/deleteTags/${payload}`).then((response) => {
            // commit('Set_Tags', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },

      Restore_Tag({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.post(`api/restoreTag/${payload}`).then((response) => {
            // commit('Set_Tags', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
      
      Update_Tag({ commit }, payload) {
        return new Promise((resolve, reject) => {
          const { id, data } = payload;
          api.put(`api/updateTags/${id}`, data).then((response) => {
            //console.log(response)
            // commit('Set_Single_Tag', response.data);
            resolve(response.data);
          }).catch((error) => {
            reject(error);
          });
        });
      },
  
 
      Search_Tags({ commit }, payload) {
        commit("Set_Tag_SearchQuery", payload);
        commit("Set_Tag_Loading", true);
  
        api.get(`api/searchTags?search=${payload}`)
          .then((response) => {
            //console.log(response)
            commit("Set_Tag_Loading", false);
            commit("Set_Tags", response.data);
          })
          .catch((error) => {
            commit("Set_Tag_Loading", false);
          });
      },

   
  },
};

import api from '@/api/index'

export default {
  state: {
    RESEARCHES_COUNT: null,
    RESEARCHES_COUNT_BY_YEAR: [],
    RESEARCHES_COUNT_BY_ALL_MONTHS: [],
    chartSeriesCategories: [],
    chartTags: [],
    piechart: [], // Store the chart data here
    
  },

  getters: {
    RESEARCHES_COUNT: (state) => state.RESEARCHES_COUNT,
    RESEARCHES_COUNT_BY_YEAR: (state) => state.RESEARCHES_COUNT_BY_YEAR,
    RESEARCHES_COUNT_BY_ALL_MONTHS: (state) => state.RESEARCHES_COUNT_BY_ALL_MONTHS,
    Get_allCat: (state)=> state.chartSeriesCategories,
    Get_allTag: (state)=> state.chartTags,
    Get_Pie :(state)=>state.piechart,
  },

  mutations: {
    RESEARCHES_COUNT: (state, data) => { state.RESEARCHES_COUNT = data },
    RESEARCHES_COUNT_BY_YEAR: (state, data) => { state.RESEARCHES_COUNT_BY_YEAR = data },
    RESEARCHES_COUNT_BY_ALL_MONTHS: (state, data) => { state.RESEARCHES_COUNT_BY_ALL_MONTHS = data },
    Set_Cat(state, data) {
      state.chartSeriesCategories = data;
    },

    Set_Tag(state, data) {
      state.chartTags = data;
    },
    Set_pie(state, data) {
      state.piechart = data;
    },
   
  },

  actions: {
    GetReasearchesCount({ commit }) {
      return new Promise((resolve, reject) => {
        api.get('api/GetReasearchesCount').then((response) => {
          commit('RESEARCHES_COUNT', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetResearchCountByAllYears({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetResearchCountByAllYears',payload).then((response) => {
          commit('RESEARCHES_COUNT_BY_YEAR', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetResearchCountByAllMonths({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetResearchCountByAllMonths',payload).then((response) => {
          commit('RESEARCHES_COUNT_BY_ALL_MONTHS', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    GetCategories({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetCategoryCounts',payload).then((response) => {
          commit('Set_Cat', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },

    GetTagscount({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/GetTagsCounts',payload).then((response) => {
          commit('Set_Tag', response.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    Piechart({ commit },payload) {
      return new Promise((resolve, reject) => {
        api.get('api/Piechart',payload).then((response) => {
          commit('Set_pie', response.data);
          resolve(response.data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
  }
}
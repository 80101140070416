<template>
  <v-app style="background: #f1f2f7">
    <v-container class="container">
      <!-- ... (Your existing code) ... -->
      <v-row class="mt-5">
        <v-col cols="12">
          <v-card elevation="0" class="graph">
            <div class="research-graph">
              <p class="font-weight-bold">Researches by Departments</p>

              <v-row v-if="!this.isProfs || this.isAdmin || this.isCoor || this.isDean">
                <v-col cols="6">
                  <v-card elevation="0" outlined>
                    <apexchart type="bar" :options="chartOptionsCategories" :series="chartSeriesCategories"></apexchart>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card elevation="0" outlined>
                    <apexchart type="bar" :options="chartOptionsTags" :series="chartSeriesTags"></apexchart>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="!this.isProfs || this.isAdmin || this.isCoor || this.isDean ">
                <v-col cols="6">
                  <v-card elevation="0" outlined>
                    <apexchart type="pie" :options="chartOptionsPie" :series="chartSeriesPie"></apexchart>
                    <!-- <apexchart type="donut" :options="chartOptionsPie" :series="chartSeriesPie"></apexchart> -->
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      "Get_allCat", "Get_allTag",
      "Get_Pie",'USER_DETAILS'
    ]),
    isProfs() {
    return (
      this.USER_DETAILS &&
      this.USER_DETAILS.user_role_details &&
      this.USER_DETAILS.user_role_details.some(role => role.id === 4)
    );
  },

    isAdmin() {
      return this.USER_DETAILS.user_role_details.some(role => role.id === 1);
    },
    isDean() {
      return this.isAdmin || this.USER_DETAILS.user_role_details.some(role => role.id === 2);
    },
    isCoor() {
      return this.USER_DETAILS.user_role_details.some(role => role.id === 3);
    },
 
    chartOptionsCategories() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.categoryVal,
        },
        yaxis: {
          stacked: true,
        },
        title: {
          text: 'Category',
        },
        legend: {
          show: true,
        },
      };
    },

    chartSeriesCategories() {
      return this.chartSeriescat;
    },

    chartOptionsTags() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          categories: this.tagVal,
        },
        yaxis: {
          stacked: true,
        },
        title: {
          text: 'Tags',
        },
        legend: {
          show: true,
        },
      };
    },

    chartSeriesTags() {
      return this.chartSeriestag;
    },



    
    chartOptionsPie() {
  return {
    chart: {
      type: 'pie',
    },
    labels: ['Research Contributors', 'Non Research Contributors'], // Add your static labels
    title: {
      text: 'Pie Chart',
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        customIcons: []
      },
    },
    colors: ['#36A2EB', '#FF6384'],
  };
},

chartSeriesPie() {
  return this.chartData;
},


  },



  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      categoryVal: [],
      chartSeriescat: [],
      tagVal: [],
      chartSeriestag: [],
      chartData: [],

    };
  },

  created() {

//    

    this.$store
      .dispatch('GetCategories')
      .then(() => {
        this.categoryVal = this.Get_allCat.map((item) => item.name);
        this.chartSeriescat = this.Get_allCat;

        //console.log(this.Get_allCat);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });


    this.$store
      .dispatch('GetTagscount')
      .then(() => {
        this.tagVal = this.Get_allTag.map((item) => item.name);
        this.chartSeriestag = this.Get_allTag;

        // console.log(this.Get_allCat);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });

    this.$store
      .dispatch('Piechart')
      .then(() => {
        console.log('Get_Pie data:', this.Get_Pie);
        this.chartData = this.Get_Pie;
      })
      .catch((error) => {
        console.error('Error fetching Piechart data:', error);
      });


      
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"30rem"},model:{value:(_vm.Get_Colleges_Dialog_Status),callback:function ($$v) {_vm.Get_Colleges_Dialog_Status=$$v},expression:"Get_Colleges_Dialog_Status"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.Get_Colleges_Dialog_State_Mode))]),_c(VCardText,[_c(VForm,{ref:"form"},[(_vm.Get_Colleges_Dialog_State_Mode == 'Add')?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Colleges","rules":_vm.rules,"hide-details":"auto","outlined":""},model:{value:(_vm.payload.name),callback:function ($$v) {_vm.$set(_vm.payload, "name", $$v)},expression:"payload.name"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Abbreviation","rules":_vm.rules,"hide-details":"auto","outlined":""},model:{value:(_vm.payload.abbvreviation),callback:function ($$v) {_vm.$set(_vm.payload, "abbvreviation", $$v)},expression:"payload.abbvreviation"}})],1)],1):_vm._e()],1),_c(VForm,{ref:"form_update"},[(_vm.Get_Colleges_Dialog_State_Mode == 'Update')?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Edit Colleges","rules":_vm.rules,"hide-details":"auto","outlined":""},model:{value:(_vm.payload.editColleges),callback:function ($$v) {_vm.$set(_vm.payload, "editColleges", $$v)},expression:"payload.editColleges"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Edit Abbreviation","rules":_vm.rules,"hide-details":"auto","outlined":""},model:{value:(_vm.payload.editAbbreviation),callback:function ($$v) {_vm.$set(_vm.payload, "editAbbreviation", $$v)},expression:"payload.editAbbreviation"}})],1)],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.closeDialogs()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.Get_Colleges_Dialog_State_Mode == 'Add'
            ? _vm.addColleges()
            : _vm.updateCollege()}}},[_vm._v(" "+_vm._s(_vm.Get_Colleges_Dialog_State_Mode)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }